import { CalioSelectFormModel } from "@client-models/calio-select-form.model";
import { LanguagesModel } from "../db-models/language.model";

export const APP_CONSTANTS = {
  DEFAULT_CALENSO_PARTNER_NAME: 'calenso',
  CALENSO_SUPPORT_EMAIL: 'support@calenso.com',
  APPOINTMENT: 'APPOINTMENT',
  EVENT: 'EVENT',
  COMPACT: 'COMPACT',
  CONFERENCE: 'CONFERENCE',
  ANY: 'ANY',
  WORKER: 'WORKER',
  WEBHOOK: 'WEBHOOK',
  LEADTIME: 'LEADTIME',

  LIST: 'LIST',
  GRID: 'GRID',

  ADD: 'ADD',
  EDIT: 'EDIT',
  CREATE: 'CREATE',

  AVAILABILITIES_FORM: 'AVAILABILITIES_FORM',
  EXCEPTIONAL_AVAILABILITIES_FORM: 'EXCEPTIONAL_AVAILABILITIES_FORM',

  APPOINTMENT_SERVICE_NEW_FORM_TYPE: 'APPOINTMENT_SERVICE_NEW_FORM_TYPE',
  APPOINTMENT_SERVICE_CATEGORY_FORM_TYPE: 'APPOINTMENT_SERVICE_CATEGORY_FORM_TYPE',
  APPOINTMENT_SERVICE_EDIT_FORM_TYPE: 'APPOINTMENT_SERVICE_EDIT_FORM_TYPE',

  APPOINTMENT_SERVICE_CATEGORY_NEW_FORM_TYPE: 'APPOINTMENT_SERVICE_CATEGORY_NEW_FORM_TYPE',
  APPOINTMENT_SERVICE_CATEGORY_EDIT_FORM_TYPE: 'APPOINTMENT_SERVICE_CATEGORY_EDIT_FORM_TYPE',

  CATEGORY_NEW_FORM_TYPE: 'CATEGORY_NEW_FORM_TYPE',
  CATEGORY_EDIT_FORM_TYPE: 'CATEGORY_EDIT_FORM_TYPE',

  CUSTOMER_TYPE_NEW_FORM_TYPE: 'CUSTOMER_TYPE_NEW_FORM_TYPE',
  CUSTOMER_TYPE_EDIT_FORM_TYPE: 'CUSTOMER_TYPE_EDIT_FORM_TYPE',

  STORE_NEW_FORM_TYPE: 'STORE_NEW_FORM_TYPE',
  STORE_EDIT_FORM_TYPE: 'STORE_EDIT_FORM_TYPE',

  APPOINTMENT_STATUS_NEW_FORM_TYPE: 'APPOINTMENT_STATUS_NEW_FORM_TYPE',
  APPOINTMENT_STATUS_EDIT_FORM_TYPE: 'APPOINTMENT_STATUS_EDIT_FORM_TYPE',

  SMART_WIDGET_NEW_FORM_TYPE: 'SMART_WIDGET_NEW_FORM_TYPE',
  SMART_WIDGET_EDIT_FORM_TYPE: 'SMART_WIDGET_EDIT_FORM_TYPE',
  SMART_WIDGET_SURVEY: 'SMART_WIDGET_SURVEY',
  SMART_WIDGET_BOOKING: 'SMART_WIDGET_BOOKING',
  SMART_WIDGET_LEAD_GENERATOR: 'SMART_WIDGET_LEAD_GENERATOR',

  APPOINTMENT_GROUP_NEW_FORM_TYPE: 'APPOINTMENT_GROUP_NEW_FORM_TYPE',
  APPOINTMENT_GROUP_EDIT_FORM_TYPE: 'APPOINTMENT_GROUP_EDIT_FORM_TYPE',

  TAX_NEW_FORM_TYPE: 'TAX_NEW_FORM_TYPE',
  TAX_EDIT_FORM_TYPE: 'TAX_EDIT_FORM_TYPE',

  DEAL_NEW_FORM_TYPE: 'DEAL_NEW_FORM_TYPE',
  DEAL_EDIT_FORM_TYPE: 'DEAL_EDIT_FORM_TYPE',

  DEAL_AMOUNT_SCREEN: 'DEAL_AMOUNT_SCREEN',
  DEAL_DURATION_SCREEN: 'DEAL_DURATION_SCREEN',
  DEAL_COUPON_SCREEN: 'DEAL_COUPON_SCREEN',

  STORE_ONLINE: 'STORE_ONLINE',
  STORE_OFFLINE: 'STORE_OFFLINE',

  FEATURE_PROPOSAL_REDIRECTION_LINK: 'https://calenso.feedbear.com/boards/feature-proposals',

  QUESTION_FIELD_NEW_FORM_TYPE: 'QUESTION_FIELD_NEW_FORM_TYPE',
  QUESTION_FIELD_EDIT_FORM_TYPE: 'QUESTION_FIELD_EDIT_FORM_TYPE',
  QUESTION_FIELD_MULTILANGUAGE_FORM_TYPE: 'QUESTION_FIELD_MULTILANGUAGE_FORM_TYPE',

  INTEGRATION_IFRAME: 'INTEGRATION_IFRAME',
  INTEGRATION_WEBCOMPONENT: 'INTEGRATION_WEBCOMPONENT',
  INTEGRATION_EMAIL_SIGNATURE: 'INTEGRATION_EMAIL_SIGNATURE',

  RESELLER_INTEGRATION_WEBCOMPONENT: 'RESELLER_INTEGRATION_WEBCOMPONENT',
  RESELLER_INTEGRATION_IFRAME: 'RESELLER_INTEGRATION_IFRAME',
  RESELLER_INTEGRATION_URL: 'RESELLER_INTEGRATION_URL',

  EMPLOYEE_NEW_FORM_TYPE: 'EMPLOYEE_NEW_FORM_TYPE',
  RESOURCE_NEW_FORM_TYPE: 'RESOURCE_NEW_FORM_TYPE',
  EMPLOYEE_STORE_FORM_TYPE: 'EMPLOYEE_STORE_FORM_TYPE',
  EMPLOYEE_EDIT_FORM_TYPE: 'EMPLOYEE_EDIT_FORM_TYPE',
  RESOURCE_EDIT_FORM_TYPE: 'RESOURCE_EDIT_FORM_TYPE',
  EMPLOYEE_PASSWORD_FORM_TYPE: 'EMPLOYEE_PASSWORD_FORM_TYPE',
  AVAILABILITIES_FIRST_SCREEN: 'AVAILABILITIES_FIRST_SCREEN',
  AVAILABILITIES_RECURRING_SCREEN: 'AVAILABILITIES_RECURRING_SCREEN',
  AVAILABILITIES_ABSENCES_SCREEN: 'AVAILABILITIES_ABSENCES_SCREEN',
  CUSTOM_ABSENCES_SCREEN: 'CUSTOM_ABSENCES_SCREEN',
  IMPORT_HOLIDAYS_ABSENCES_SCREEN: 'IMPORT_HOLIDAYS_ABSENCES_SCREEN',
  EVENTS_BASIC_SCREEN: 'EVENTS_BASIC_SCREEN',
  EVENTS_NOTIFICATION_SCREEN: 'EVENTS_NOTIFICATION_SCREEN',
  EVENTS_CANCELLATION_SCREEN: 'EVENTS_CANCELLATION_SCREEN',
  URL_REGEX: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  NEW_URL_REGEX: /^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[^\s]*)?$/,
  EMAIL_REGEX: /^[\p{L}0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[\p{L}0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[_\p{L}0-9][-_\p{L}0-9]*\.)*(?:[\p{L}0-9][-\p{L}0-9]{0,62})\.(?:(?:[a-z]{2}\.)?[a-z]{2,})$/iu,
  PLACEHOLDER_MULTIPLE_EMAIL_REGEX: /^(\s*(\{\{\s*[A-Z_]+\s*\}\}|[\p{L}0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[\p{L}0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[_\p{L}0-9][-_\p{L}0-9]*\.)*(?:[\p{L}0-9][-\p{L}0-9]{0,62})\.(?:(?:[a-z]{2}\.)?[a-z]{2,}))\s*)(,\s*(\{\{\s*[A-Z_]+\s*\}\}|[\p{L}0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[\p{L}0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[_\p{L}0-9][-_\p{L}0-9]*\.)*(?:[\p{L}0-9][-\p{L}0-9]{0,62})\.(?:(?:[a-z]{2}\.)?[a-z]{2,}))\s*)*$/iu,
  PLACEHOLDER_EMAIL_REGEX: /^(\s*(\{\{\s*[A-Z_]+\s*\}\}|[\p{L}0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[\p{L}0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[_\p{L}0-9][-_\p{L}0-9]*\.)*(?:[\p{L}0-9][-\p{L}0-9]{0,62})\.(?:(?:[a-z]{2}\.)?[a-z]{2,}))\s*)$/iu,
  PLACEHOLDER_PHONE_REGEX: /^(?:\{\{\s*[A-Z_]+\s*\}\}|(\+?[0-9]{5,15}))$/,
  WORKER_PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.{8,})/g,
  WORKER_USERNAME_REGEX: /^[A-Za-z0-9_@./!#$%&.'*+-\/=?^_]+$/,
  WORKER_CONFORENCE_URL_REGEX: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,5})?(\/.*)?$/,
  IP_REGEX: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(?:[0-9]|[1-2][0-9]|3[0-2])$|^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  COMMON_ZIP_REGEX: /^[a-zA-Z0-9\s-]{1,10}$/,
  ZIP_REGEX: /^((\d{4} [A-Z]{2})|(\d{3,6})|(\d{5}(-\d{4})?)|([A-Z0-9]{1,4} [A-Z0-9]{1,4}))$/,
  HOST_REGEX: /^(?=.{1,253}$)(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.?)+[A-Za-z]{2,6}$/,
  PORT_REGEX: /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
  HEADER_REGEX: /^[a-zA-Z][a-zA-Z0-9_-]*$/,
  VALUE_REGEX: /^(?!\s*$)[a-zA-Z0-9\s]+$/,
  INFO_URL_REGEX: /^(https?:\/\/)(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/,
  CUSTOM_EMAIL_TEMPLATE_PLACEHOLDER_REGEX: /^(|\s*(.|\n)*{{\s*body\s*}}(.|\n)*\s*)$/,
  ALPHANUMERIC: /^[a-zA-Z0-9_\- ]*$/,
  NUNBER_ONLY: /^\d+$/,
  BOOKING_NAME_REGEX: /^[a-zA-Z0-9äöüÄÖÜß]+$/,
  IDEAL_REGEX: /^((\\.|[^\\\[\]\{\}\(\)\|])|(\[[^\]]*\])|(\([^\)]*\))|(\{[0-9,]*\}))*$/,

  // tslint:disable: quotemark
  REGEX_PATTERNS: [
    {
      name: 'regex_pattern.only_uppercase',
      pattern: "^[A-Z]+$",
    },
    {
      name: 'regex_pattern.only_lowercase',
      pattern: "^[a-z]+$",
    },
    {
      name: 'regex_pattern.only_numbers',
      pattern: "^[0-9]+$",
    },
    {
      name: 'regex_pattern.only_letters',
      pattern: "^[a-zA-Z]+$",
    },
    {
      name: 'regex_pattern.only_letters_and_numbers',
      pattern: "^[a-zA-Z0-9]+$",
    },
    {
      name: 'regex_pattern.only_letters_and_numbers_with_spaces',
      pattern: "^[a-zA-Z0-9 ]+$",
    },
    {
      name: 'regex_pattern.only_letters_and_numbers_without_spaces',
      pattern: "^[a-zA-Z0-9]+$",
    },
    {
      name: 'regex_pattern.insurance_number',
      pattern: "^(807)[0-9]{17}$",
    },
    {
      name: 'regex_pattern.phone_number',
      pattern: "^[0-9]{10}$",
    },
    {
      name: 'regex_pattern.ahv_number',
      pattern: "^(756)[0-9.]{13}$",
    },
    {
      name: 'regex_pattern.iban_number',
      pattern: "^[A-Z]{2}\d{2} (?:\d{4} ){3}\d{4}(?: \d\d?)?$",
    },
    {
      name: 'regex_pattern.url',
      pattern: "^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$",
    },
    {
      name: 'regex_pattern.email',
      pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$",
    },
    {
      name: 'regex_pattern.custom',
      pattern: ''
    }
  ],
  // tslint:enable: quotemark

  COLORS: [
    {
      label: 'Danger',
      value: 'danger'
    },
    {
      label: 'Dark',
      value: 'dark'
    },
    {
      label: 'Info',
      value: 'info'
    },
    {
      label: 'Light',
      value: 'light'
    },
    {
      label: 'Primary',
      value: 'primary'
    },
    {
      label: 'Secondary',
      value: 'secondary'
    },
    {
      label: 'Success',
      value: 'success'
    },
    {
      label: 'Warning',
      value: 'warning'
    },
  ],

  DAYS: [
    {
      sn_text: 'mon',
      ln_text: 'monday',
      value: 1,
      calValue: 1,
    },
    {
      sn_text: 'tue',
      ln_text: 'tuesday',
      value: 2,
      calValue: 2,
    },
    {
      sn_text: 'wed',
      ln_text: 'wednesday',
      value: 3,
      calValue: 3,
    },
    {
      sn_text: 'thu',
      ln_text: 'thursday',
      value: 4,
      calValue: 4,
    },
    {
      sn_text: 'fri',
      ln_text: 'friday',
      value: 5,
      calValue: 5,
    },
    {
      sn_text: 'sat',
      ln_text: 'saturday',
      value: 6,
      calValue: 6,
    },
    {
      sn_text: 'sun',
      ln_text: 'sunday',
      value: 7,
      calValue: 0,
    },
  ],
  PUBLIC_HOLIDAY_TYPES: {
    SWISS: [
      {
        text: 'school_holidays_text_1',
        value: 0,
      },
      {
        text: 'school_holidays_text_2',
        value: 3,
      },
      {
        text: 'school_holidays_text_3',
        value: 4,
      },
      {
        text: 'school_holidays_text_4',
        value: 5,
      },
    ],
    OTHER_LANGS: [
      {
        text: 'other_school_holidays_text_2',
        value: 3,
      },
      {
        text: 'other_school_holidays_text_3',
        value: 4,
      },
      {
        text: 'other_school_holidays_text_4',
        value: 5,
      },
    ],
  },
  LANGUAGES: [
    {
      text: 'german',
      origin_text: 'deutsch',
      value: 'de',
      secondary_text: 'DE',
      locale: 'de_CH',
      flagIcon: '/assets/images/flags/if_Flag_of_Switzerland_-_Circle.png',
    },
    {
      text: 'french',
      origin_text: 'français',
      value: 'fr',
      secondary_text: 'FR',
      locale: 'fr_CH',
      flagIcon: '/assets/images/flags/if_Flag_of_France_-_Circle.png',
    },
    {
      text: 'italian',
      origin_text: 'italiano',
      value: 'it',
      secondary_text: 'IT',
      locale: 'it_CH',
      flagIcon: '/assets/images/flags/if_Flag_of_Italy_-_Circle.png',
    },
    {
      text: 'english',
      origin_text: 'english',
      value: 'en',
      secondary_text: 'EN',
      locale: 'en_US',
      flagIcon: '/assets/images/flags/if_Flag_of_United_States_-_Circle.png',
    },
    {
      text: 'dutch',
      origin_text: 'nederlands',
      value: 'nl',
      secondary_text: 'NL',
      locale: 'nl_NL',
      flagIcon: '/assets/images/flags/dutch.png',
    },
    {
      text: 'finnish',
      origin_text: 'suomalainen',
      value: 'fi',
      secondary_text: 'FI',
      locale: 'fi_FI',
      flagIcon: '/assets/images/flags/finnish.png',
    },
    {
      text: 'polish',
      origin_text: 'polski',
      value: 'pl',
      secondary_text: 'PL',
      locale: 'pl_PL',
      flagIcon: '/assets/images/flags/polish.png',
    },
    {
      text: 'spanish',
      origin_text: 'español',
      value: 'es',
      secondary_text: 'ES',
      locale: 'es_ES',
      flagIcon: '/assets/images/flags/spanish.png',
    },
    {
      text: 'swedish',
      origin_text: 'svenska',
      value: 'sv',
      secondary_text: 'SE',
      locale: 'sv_SE',
      flagIcon: '/assets/images/flags/swedish.png',
    }
  ] as LanguagesModel[],

  IMAGE_UPLOAD_TYPE: {
    PARTNER_LOGO_UPLOAD: 'PARTNER_LOGO_UPLOAD',
    WORKER_AVATAR_UPLOAD: 'WORKER_AVARTAR_UPLOAD',
    EVENT_IMAGE_UPLOAD: 'EVENT_IMAGE_UPLOAD',
    PARTNER_LANDINGPAGE_LOGO_UPLOAD: 'PARTNER_LANDINGPAGE_LOGO_UPLOAD',
    PARTNER_FAVICON_LOGO_UPLOAD: 'PARTNER_FAVICON_LOGO_UPLOAD',
    PARTNER_EMAIL_LOGO_UPLOAD: 'PARTNER_EMAIL_LOGO_UPLOAD',
    STORE_LOGO_UPLOAD: 'STORE_LOGO_UPLOAD',
    BOOKING_PAGE_BACKGROUND: 'BOOKING_PAGE_BACKGROUND'
  },
  INTEGRATION_FIRST_SCREEN: 'INTEGRATION_FIRST_SCREEN',
  INTEGRATION_BOOKING_MICROSITE_SCREEN: 'INTEGRATION_BOOKING_MICROSITE_SCREEN',
  INTEGRATION_WEBSITE_SCREEN: 'INTEGRATION_WEBSITE_SCREEN',

  PROBLEM_NOTIFICATION: 'problem',
  ANNOUNCEMENT_NOTIFICATION: 'announcement',
  WARNING_NOTIFICATION: 'warning',
  INFORMATION_NOTIFICATION: 'information',

  CASH_ON_SITE_SCREEN: 'CASH_ON_SITE_SCREEN',
  CREDIT_CARD_SCREEN: 'CREDIT_CARD_SCREEN',

  PERCENTAGE_SCREEN: 'PERCENTAGE_SCREEN',
  FIXED_AMOUNT_SCREEN: 'FIXED_AMOUNT_SCREEN',

  REPEATING_DAYS: 'REPEATING_DAYS',
  REPEATING_DATES: 'REPEATING_DATES',

  CUSTOM_FIELD_SELECT: 'select',
  CUSTOM_FIELD_TEXT: 'text',
  CUSTOM_FIELD_TEXT_AREA: 'textarea',
  CUSTOM_FIELD_CHECKBOX: 'checkbox',
  CUSTOM_FIELD_FILE: 'file',
  CUSTOM_FIELD_RADIO: 'radio',
  CUSTOM_FIELD_DATE: 'date',
  CUSTOM_FIELD_INFORMATION: 'information',
  CUSTOM_FIELD_NUMBER_SELECT_CARD: 'number-select',
  CUSTOM_FIELD_IMAGE_SELECT_CARD: 'image-select',
  CUSTOM_FIELD_SELECT_CARD: 'card-select',
  CUSTOM_FIELD_WORKER_SELECT: 'worker-select',
  DE_SCREEN: 'DE',
  EN_SCREEN: 'EN',
  IT_SCREEN: 'IT',
  FR_SCREEN: 'FR',
  NL_SCREEN: 'NL',

  SUPPORTED_COLOR_FORMATS: [
    {
      display: 'neutral_color',
      value: 'neutral',
    },
    {
      display: 'red_color',
      value: 'red',
    },
    {
      display: 'yellow_color',
      value: 'yellow',
    },
    {
      display: 'blue_color',
      value: 'blue',
    },
  ],
};


export const WIDGET_EXPAND_OPTIONS: any[] = [
  {
    display: 'widget_expand_appointment_service_categories_option_1',
    value: 0,
  },
  {
    display: 'widget_expand_appointment_service_categories_option_2',
    value: 1,
  },
  {
    display: 'widget_expand_appointment_service_categories_option_3',
    value: 2,
  },
];

export const CANCELLATION_NOTIFICATION_TYPES = [
  {
    display: 'inform_the_partner',
    value: 1,
  },
  {
    display: 'worker_option',
    value: 2,
  },
  {
    display: 'custom_email_option',
    value: 3,
  },
  {
    display: 'inform_store',
    value: 4,
  },
  {
    display: 'inform_partner_and_resource',
    value: 5,
  },
  {
    display: 'inform_store_and_resource',
    value: 6,
  },
  {
    display: 'inform_partner_and_store',
    value: 7,
  }
];

export const NOTIFICATION_TYPES = [
  {
    display: 'email',
    value: 'email',
  },
  {
    display: 'sms',
    value: 'sms',
  },
  {
    display: 'both_sms_email',
    value: 'both',
  },
];

export const SINGLE_WORKER_NOTIFICATIONS = [
  {
    display: 'inform_the_partner',
    value: 0,
  },
  {
    display: 'inform_each_employee',
    value: 1,
  },
  {
    display: 'inform_resource_and_partner',
    value: 2,
  },
  {
    display: 'inform_store',
    value: 3,
  },
  {
    display: 'inform_store_and_resource',
    value: 4,
  },
  {
    display: 'inform_partner_and_store',
    value: 5,
  },
];

export const APPOINTMENT_CONFIRMATION_TYPES = [
  {
    display: 'inform_each_employee',
    value: 1,
  },
  {
    display: 'inform_the_partner',
    value: 0,
  },
];

export const COURTESY_FORM_TYPES = [
  {
    display: 'informal',
    value: 'Du',
  },
  {
    display: 'formal',
    value: 'Sie',
  },
];

export const HOUR_TYPES = [
  {
    display: '1 ',
    value: 1,
  },
  {
    display: '2 ',
    value: 2,
  },
  {
    display: '3 ',
    value: 3,
  },
  {
    display: '4 ',
    value: 4,
  },
  {
    display: '5 ',
    value: 5,
  },
  {
    display: '6 ',
    value: 6,
  },
  {
    display: '12 ',
    value: 12,
  },
  {
    display: '24 ',
    value: 24,
  },
  {
    display: '36 ',
    value: 36,
  },
  {
    display: '48 ',
    value: 48,
  },
  {
    display: '72 ',
    value: 72,
  },
];

export const CALENDAR_EVENT_REQUEST_TYPE: any = {
  EVENT_CREATED: 'EVENT_CREATED',
  EVENT_UPDATED: 'EVENT_UPDATED',
  EVENT_DELETED: 'EVENT_DELETED',
};

export const CALENDAR_EVENT_TYPE: any = {
  CALENSO: 'CALENSO',
  SOCIAL: 'SOCIAL',
  APPOINTMENT: 'APPOINTMENT',
  ABSENSE: 'ABSENSE',
};

export const CALENDAR_COLOR_CONSTANT: { key: string; value: string }[] = [
  {
    key: 'white',
    value: '#ffffff',
  },
  {
    key: 'black',
    value: '#000000',
  },
  {
    key: 'red',
    value: '#fad9d5',
  },
  {
    key: 'vacation',
    value: 'background: repeating-linear-gradient(45deg, rgba(155, 155, 155, 0.5), rgba(155, 155, 155, 0.5) 8px, transparent 8px, transparent 16px);color: #666;',
  },
  {
    key: 'green',
    value: '#d2f6d5',
  },
  {
    key: 'blue',
    value: '#AACDFC',
  },
  {
    key: 'violett',
    value: '#B1B2FD',
  },
  {
    key: 'orange',
    value: '#ffeed6',
  },
  {
    key: 'yellow',
    value: '#ffee9a',
  },
  {
    key: 'google',
    value: '#9A9DA2',
  },
  {
    key: 'icloud',
    value: '#9A9DA2',
  },
  {
    key: 'exchange',
    value: '#9A9DA2',
  },
  {
    key: 'office365',
    value: '#9A9DA2',
  },
  {
    key: 'external',
    value: '#9A9DA2',
  },
];

export const DELETE_CONST_TYPE: { [key: string]: string } = {
  DEFAULT: 'DEFAULT',
  ICON: 'ICON',
  ICON_AND_LABEL: 'ICON_AND_LABEL',
  X_BUTTON: 'X_BUTTON',
  SELECTED_DELETE_BUTTON: 'SELECTED_DELETE_BUTTON',
  LABEL_BUTTON: 'LABEL_BUTTON',
  X_FA_ICON: 'X_FA_ICON',
};

export const EMPLOYEE_CONST_TYPE = {
  AVATAR: 'AVATAR',
  SELECT: 'SELECT',
  SELECT_AVATAR: 'SELECT_AVATAR',
  SMALL_MULTISELECT_AVATAR: 'SMALL_MULTISELECT_AVATAR',
};

export const FILE_CONST_TYPE = {
  PDF: 'PDF',
  EXCEL: 'EXCEL',
};

export const MEETING_TYPE = {
  ZOOM: 'zoom',
  CALENSO: 'calenso',
  PHONE: 'phone',
  STORE: 'store',
  CUSTOMER: 'customer',
  UNBLU: 'unblu',
  MICROSOFT_TEAMS: 'teams',
  GOOGLE_MEET: 'meet',
  GOTO_MEETING: 'gotomeeting',
  WEBEX: 'webex',
  WILL_CALL_YOU: 'wewillcallyou',
  METAVERSE: 'metaverse'
};

export const MEETING_TYPE_ID = {
  STORE: 1,
  ZOOM: 2,
  CALENSO: 3,
  PHONE: 4,
  CUSTOMER: 5,
  UNBLU: 6,
  MICROSOFT_TEAMS: 7,
  GOOGLE_MEET: 8,
  GOTO_MEETING: 9,
  WEBEX: 10,
  WILL_CALL_YOU: 11,
  GROUP_APPOINTMENT_PLACE: 12,
  METAVERSE: 13
};

export const CALENDAR_TYPE = {
  OFFICE_365: 'office365',
  EXCHANGE: 'exchange',
  ICLOUD: 'icloud',
  GOOGLE: 'google',
  CALDAV: 'caldav',
  CALENSO: 'calenso'
};

export const CALENDAR_TYPE_NAMES = {
  office365: 'Office365',
  exchange: 'Microsoft Exchange',
  icloud: 'iCloud',
  google: 'Google Calendar',
  caldav: 'CalDAV',
  calenso: 'Calenso'
};

export const CALENDAR_TYPE_IMAGE_PATH = {
  office365: '/assets/images/calendar/microsoft365-icon.svg',
  exchange: '/assets/images/calendar/exchange-icon.svg',
  icloud: '/assets/images/calendar/icloud-icon.svg',
  google: '/assets/images/calendar/google-icon.svg',
  caldav: '/assets/images/calendar/caldav-icon.svg',
  calenso: '/assets/images/calendar/calenso-icon.svg'
};

export const EXPORT_API_TYPE = {
  EVENT_OVERVIEW: 'event_overview',
  EVENT_DETAIL: 'event_detail',
  SLOT_DETAIL: 'slot_detail',
};

export const EMAIL_TEMPLATE_CONST = {
  de_CH: {
    finish: 'Freundliche Grüsse<br>[PARTNER_NAME]',
    footer: 'Diese E-Mail wurde automatisch von <a href="https://www.calenso.com" target="_blank">Calenso</a> erzeugt.',
    intro: 'Du hast erfolgreich einen Termin bei der Firma <b>[PARTNER_NAME]</b> in [LOCATION] vereinbart. <br><br>Wir haben den Termin direkt dieser E-Mail angehängt, damit Du ihn bequem in deinen Kalender einfügen kannst!',
    salutation: 'Hallo [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  en_US: {
    finish: 'Kind regards<br>[PARTNER_NAME]',
    footer: 'This email was automatically generated by <a href="https://www.calenso.com" target="_blank">Calenso</a>.',
    intro: 'You have successfully arranged an appointment with the company <b>[LOCATION]</b> in [ORT]. <br><br>We have attached the appointment directly to this e-mail so that you can easily add it to your calendar!',
    salutation: 'Dear [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  it_CH: {
    finish: 'Cordiali saluti<br>[PARTNER_NAME]',
    footer: 'Questa e-mail è stata generata automaticamente da <a href="https://www.calenso.com" target="_blank">Calenso</a>.',
    intro: 'hai fissato un appuntamento presso l’azienda <b>[PARTNER_NAME]</b> di [LOCATION]. <br><br>Abbiamo allegato l’appuntamento direttamente a questa e-mail per consentirti di inserirlo comodamente nel tuo calendario.',
    salutation: 'Salve [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  fr_CH: {
    finish: 'Meilleures salutations<br>[PARTNER_NAME]',
    footer: 'Cet e-mail a été généré automatiquement par <a href="https://www.calenso.com" target="_blank">Calenso</a>.',
    intro: 'Vous avez organisé avec succès plusieurs rendez-vous avec la société <b> [PARTNER_NAME] </b> dans [LOCATION]. <br><br>Nous avons joint les dates directement à cet e-mail, afin que vous puissiez facilement les ajouter dans votre calendrier!',
    salutation: 'Bonjour [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  nl_NL: {
    finish: 'Met vriendelijke groet<br>[PARTNER_NAME]',
    footer: 'Deze e-mail werd automatisch gegenereerd door <a href="https://www.calenso.com" target="_blank">Calenso</a>.',
    intro: 'U hebt met succes verschillende vergaderingen georganiseerd met het bedrijf <b> [PARTNER_NAME] </b> in [LOCATION]. <br><br>We hebben de data direct bij deze e-mail gevoegd, zodat u ze gemakkelijk in uw agenda kunt zetten!',
    salutation: 'Goeiedag, [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  fi_FI: {
    finish: 'Ystävällisin terveisin<br>[PARTNER_NAME]',
    footer: 'Tämän sähköpostin on luonut automaattisesti <a href="https://www.calenso.com" target="_blank">Calenso</a>.',
    intro: 'U hebt met succes verschillende vergaderingen georganiseerd met het bedrijf <b> [PARTNER_NAME] </b> in [LOCATION]. <br><br>We hebben de data direct bij deze e-mail gevoegd, zodat u ze gemakkelijk in uw agenda kunt zetten!',
    salutation: 'Rakas, [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  pl_PL: {
    finish: 'Z poważaniem<br>[PARTNER_NAME]',
    footer: 'Ten e-mail został automatycznie wygenerowany przez <a href="https://www.calenso.com" target="_blank">Calenso</a>.',
    intro: 'Udało Ci się zorganizować kilka spotkań z firmą <b> [PARTNER_NAME] </b> w [LOCATION]. <br><br>Daty załączyliśmy bezpośrednio do tego e-maila, więc możesz je łatwo umieścić w swoim kalendarzu.!',
    salutation: 'Droga, [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  es_ES: {
    finish: 'Saludos<br>[PARTNER_NAME]',
    footer: 'Este correo ha sido generado automáticamente <a href="https://www.calenso.com" target="_blank">Calenso</a>.',
    intro: 'Usted ha completado con éxito varios versichtungen organizand con la empresa <b> [PARTNER_NAME] </b> en [LOCATION]. <br><br>Hemos adjuntado los datos directamente a este e-mail, para que puedas ponerlos fácilmente en tu calendario!',
    salutation: 'Amar, [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  sv_SE: {
    finish: 'Med vänlig hälsning<br>[PARTNER_NAME]',
    footer: 'Detta e-postmeddelande har genererats automatiskt av <a href="https://www.calenso.com" target="_blank">Calenso</a>.',
    intro: 'Du har lyckats ordna flera möten med företaget <b> [PARTNER_NAME] </b> i [LOCATION]. <br><br>Vi har bifogat datumen direkt till det här e-postmeddelandet, så att du enkelt kan lägga in dem i din kalender.',
    salutation: 'Väg, [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
};

export const RESELLER_CONSTANTS = {
  PAYMENTS: 'PAYMENTS',
  REFERRALS: 'REFERRALS',
  INVITATIONS: 'INVITATIONS',
};

export const TEMPLATE_TYPES = [
  {
    display: 'email',
    value: 'email',
  },
  {
    display: 'sms',
    value: 'sms',
  },
  {
    display: 'general',
    value: 'general',
  },
];

export const RELEASE_NOTE_TYPES: { id: number; description: string }[] = [
  {
    id: 1,
    description: 'Bugfixing',
  },
  {
    id: 2,
    description: 'New Functions',
  },
  {
    id: 3,
    description: 'Hotfix',
  },
];

export const LANG_TYPES: {
  display: string;
  value: string;
  langIdentifer: string;
}[] = [
    {
      display: 'german',
      value: 'de',
      langIdentifer: 'de_CH',
    },
    {
      display: 'french',
      value: 'fr',
      langIdentifer: 'fr_CH',
    },
    {
      display: 'english',
      value: 'en',
      langIdentifer: 'en_US',
    },
    {
      display: 'italian',
      value: 'it',
      langIdentifer: 'it_CH',
    },
    {
      display: 'dutch',
      value: 'nl',
      langIdentifer: 'nl_NL',
    },
    {
      display: 'finnish',
      value: 'fi',
      langIdentifer: 'fi_FI',
    },
    {
      display: 'polish',
      value: 'pl',
      langIdentifer: 'pl_PL',
    },
    {
      display: 'spanish',
      value: 'es',
      langIdentifer: 'es_ES',
    },
    {
      display: 'swedish',
      value: 'sv',
      langIdentifer: 'sv_SE',
    },
  ];

export const BOTTOM_TYPES: {
  display: string;
  value: string;
}[] = [
    {
      display: 'bottom_right',
      value: 'bottom-right',
    },
    {
      display: 'bottom_left',
      value: 'bottom-left',
    },
  ];

export const TRIGGER_TYPE = {
  IMMEDIATELY: 1,
};

export const CALIO_STANDARD_COLORS = {
  WHITE: '#FFFFFF',
  VIOLET: '#7676FF',
};

export const SMART_WIDGET_DEFAULT_DATA = {
  // type: 3,
  button_font_color: CALIO_STANDARD_COLORS.WHITE,
  header_font_color: CALIO_STANDARD_COLORS.WHITE,
  header_background_color: CALIO_STANDARD_COLORS.VIOLET,
  button_background_color: CALIO_STANDARD_COLORS.VIOLET,
  position: 'bottom-right',
  right_padding: 20,
  left_padding: 20,
  bottom_padding: 20,
  locale: 'de_CH',
  whitelisted_domains: 'dashboard.calenso.com,calenso.com',
  context: {
    worker_id: 0,
    appointment_ids: null,
    store_id: 2,
    date: '[TOMORROW]',
    is_internal: false,
  },
};

export const SALUTATION_TYPES: { display: string, value: string }[] = [
  {
    display: 'mr',
    value: 'm',
  },
  {
    display: 'ms',
    value: 'f',
  },
  {
    display: 'other',
    value: 'o',
  },
];

export const SMART_WIDGET_TYPES: { display: string; value: number }[] = [
  {
    display: 'lead',
    value: 1,
  },
  {
    display: 'calendar_fill',
    value: 4,
  },
  {
    display: 'booking',
    value: 3,
  },
  {
    display: 'survey',
    value: 2,
  },
];

export const SMART_WIDGET_CUSTOMER_TYPES: {
  display: string;
  value: string;
  type: string;
}[] = [
    { display: 'salutation', value: 'salutation', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'prename', value: 'prename', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'lastname', value: 'lastname', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'birthday', value: 'birthday', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'meeting_types', value: 'meeting_types', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'notification_preferences', value: 'notification_preferences', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'email', value: 'email', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'phone', value: 'phone', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'mobile', value: 'mobile', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'address', value: 'address', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'comment', value: 'comment', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'title', value: 'title', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'company', value: 'company', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'country', value: 'country', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'policy', value: 'policy', type: 'CUSTOMER_FORM_FIELD' },
  ];

export const SMART_WIDGET_LEAD_FORM_FIELDS: {
  key: string;
  isRequired?: boolean;
  isMultiLang?: boolean;
  isExpert?: boolean;
}[] = [
    // {key: 'type', isExpert: true},
    { key: 'button_font_color', isExpert: true },
    { key: 'header_font_color', isExpert: true },
    { key: 'header_background_color', isExpert: true },
    { key: 'button_background_color', isExpert: true },
    { key: 'position', isExpert: true },
    { key: 'right_padding', isExpert: true },
    { key: 'left_padding', isExpert: true },
    { key: 'bottom_padding', isExpert: true },
    { key: 'locale', isExpert: true },
    { key: 'whitelisted_domains', isExpert: true },
    { key: 'context', isExpert: true },
    { key: 'title', isMultiLang: true },
    { key: 'description', isMultiLang: true },
    { key: 'trigger_message', isMultiLang: true },
    { key: 'lead_intro_text', isMultiLang: true },
    { key: 'lead_outro_text', isMultiLang: true },
    { key: 'show_worker_avatar' },
    { key: 'worker_uuid' },
    { key: 'worker_ids' },
    { key: 'ask_for_customer_notification_preference' },
    { key: 'start_text', isMultiLang: true },
    { key: 'end_text', isMultiLang: true },
    { key: 'end_of_process_message', isMultiLang: true },
  ];

export const SMART_WIDGET_APPOINTMENT_FORM_FIELDS: {
  key: string;
  isRequired?: boolean;
  isMultiLang?: boolean;
  isExpert?: boolean;
}[] = [
    // {key: 'type'},
    { key: 'button_font_color', isExpert: true },
    { key: 'header_font_color', isExpert: true },
    { key: 'header_background_color', isExpert: true },
    { key: 'button_background_color', isExpert: true },
    { key: 'position', isExpert: true },
    { key: 'right_padding', isExpert: true },
    { key: 'left_padding', isExpert: true },
    { key: 'bottom_padding', isExpert: true },
    { key: 'locale', isExpert: true },
    { key: 'whitelisted_domains', isExpert: true },
    { key: 'context', isExpert: true },
    { key: 'title', isMultiLang: true },
    { key: 'description', isMultiLang: true },
    { key: 'trigger_message', isMultiLang: true },
    { key: 'lead_intro_text', isMultiLang: true },
    { key: 'lead_outro_text', isMultiLang: true },
    { key: 'ask_for_customer_notification_preference' },
    { key: 'show_questions_before_booking_process' },
    { key: 'show_meeting_type_picker' },
    { key: 'enable_one_page_ui', isExpert: true },
    { key: 'start_text', isMultiLang: true },
    { key: 'end_text', isMultiLang: true },
    { key: 'end_of_process_message', isMultiLang: true },
  ];

export const SMART_WIDGET_SURVEY_FORM_FIELDS: {
  key: string;
  isRequired?: boolean;
  isMultiLang?: boolean;
  isExpert?: boolean;
}[] = [
    // {key: 'type'},
    { key: 'button_font_color', isExpert: true },
    { key: 'header_font_color', isExpert: true },
    { key: 'header_background_color', isExpert: true },
    { key: 'button_background_color', isExpert: true },
    { key: 'position', isExpert: true },
    { key: 'right_padding', isExpert: true },
    { key: 'left_padding', isExpert: true },
    { key: 'bottom_padding', isExpert: true },
    { key: 'locale', isExpert: true },
    { key: 'whitelisted_domains', isExpert: true },
    { key: 'context', isExpert: true },
    { key: 'title', isMultiLang: true },
    { key: 'description', isMultiLang: true },
    { key: 'trigger_message', isMultiLang: true },
    { key: 'lead_intro_text', isMultiLang: true },
    { key: 'lead_outro_text', isMultiLang: true },
    { key: 'start_text', isMultiLang: true },
    { key: 'end_text', isMultiLang: true },
    { key: 'end_of_process_message', isMultiLang: true },
  ];

export const SMART_ACTION_INTERVALS: { display: string; value: string }[] = [
  {
    display: 'daily',
    value: 'daily',
  },
  {
    display: 'weekly',
    value: 'weekly',
  },
  {
    display: 'monthly',
    value: 'monthly',
  },
  {
    display: 'yearly',
    value: 'yearly',
  },
];

export const BOOKING_QUESTION_REORDERING = {
  SW_BOOKING_QUESTIONS_STEP: 'SW_BOOKING_QUESTIONS_STEP',
  SW_SURVEY_STEP_BOOKING_QUESTIONS: 'SW_SURVEY_STEP_BOOKING_QUESTIONS',
};

export const SMS_PROVIDERS = [
  {
    value: `SMSAPI`,
    slug: 'smsapi',
    prefRegion: 'EU (PL)',
  },
  {
    value: `Brevo`,
    slug: 'sendinblue',
    prefRegion: 'EU (DE)',
  },
  {
    value: `eCall by F24`,
    slug: 'ecall',
    prefRegion: 'CH',
  },
  {
    value: 'Swisscom',
    slug: 'swisscom',
    prefRegion: 'CH',
  },
];

export const EMAIL_PROVIDERS = [
  {
    value: 'Brevo',
    slug: 'brevo'
  },
  {
    value: 'SMTP',
    slug: 'smtp'
  }
];

export const SMS_PROVIDERS_VALUE = {
  SMS_API: 'smsapi',
  SEND_IN_BLUE: 'sendinblue',
  ECALL: 'ecall',
  SWISSCOM: 'swisscom'
}

export const EMAIL_PROVIDERS_VALUE = {
  BREVO: 'brevo',
  SMTP: 'smtp'
}

export const ENTITY_TYPE: { id: number; value: string }[] = [
  {
    id: 1,
    value: `resource`,
  },
  {
    id: 2,
    value: `appointment`,
  },
];

export const SAFERPAY_API_URL: { text: string; value: string }[] = [
  {
    text: `TEST (https://test.saferpay.com/api)`,
    value: `https://test.saferpay.com/api`,
  },
  {
    text: `PROD (https://www.saferpay.com/api)`,
    value: `https://www.saferpay.com/api`,
  },
];

export const UNBLU_AUTH_TYPE: { value: string; text: string }[] = [
  {
    text: `external_calendar_settings_component.unblu_basic_auth`,
    value: `basic`,
  },
  {
    text: `external_calendar_settings_component.unblu_OAuth2`,
    value: `oauth`,
  },
];

export const PARTNER_SETTING_TYPES: { [key: string]: string } = {
  STRING: 'string',
  INT: 'int',
  BOOLEAN: 'boolean',
};

export const DEFAULT_SELECTED_STORE_WORKERS_LIMIT_FOR_CALENDAR = 5;

export const SMART_ACTION_EXPORT_DURATION: { label: string; value: number }[] =
  [
    {
      label: 'smart_actions_component.all',
      value: 0,
    },
    {
      label: 'smart_actions_component.this_year',
      value: 1,
    },
    {
      label: 'smart_actions_component.last_year',
      value: 2,
    },
    {
      label: 'smart_actions_component.last_two_year',
      value: 3,
    },
  ];

export const WEBHOOK_ENDPOINT_TYPE: {
  id: number;
  label: string;
  name: string;
  category: string;
}[] = [
    {
      name: 'appointment',
      category: 'booking.created',
      label: 'appointment.booking.created',
      id: 1,
    },
    {
      name: 'appointment',
      category: 'booking.canclled',
      label: 'appointment.booking.cancelled',
      id: 2,
    },
    {
      name: 'appointment',
      category: 'booking.updated',
      label: 'appointment.updated',
      id: 11,
    },
    {
      name: 'event',
      category: 'booking.created',
      label: 'event.booking.created',
      id: 3,
    },
    {
      name: 'event',
      category: 'booking.cancelled',
      label: 'event.booking.cancelled',
      id: 4,
    },
    {
      name: 'appointment',
      category: 'manual.booking.created',
      label: 'appointment.manual.booking.created',
      id: 5,
    },
    {
      name: 'event',
      category: 'manual.booking.created',
      label: 'event.manual.booking.created',
      id: 6,
    },
    {
      name: 'customer',
      category: 'created',
      label: 'customer.created',
      id: 7,
    },
    {
      name: 'customer',
      category: 'updated',
      label: 'customer.updated',
      id: 8,
    },
    {
      name: 'customer',
      category: 'deleted',
      label: 'customer.deleted',
      id: 9,
    },
    {
      name: 'lead',
      category: 'created',
      label: 'lead.created',
      id: 10,
    },
  ];

export const BLOCK_RESOURCE_MODE = [
  { key: 'blockBookingTime', value: '1' },
  { key: 'blockEntireDay', value: '2' },
];

export const WIDGET_HINT_COLORS = [
  { key: 'primary', value: 'Primary' },
  { key: 'secondary', value: 'Secondary' },
  { key: 'success', value: 'Success' },
  { key: 'danger', value: 'Danger' },
  { key: 'warning', value: 'Warning' },
  { key: 'info', value: 'Info' },
  { key: 'light', value: 'Light' },
  { key: 'dark', value: 'Dark' },
];

export const SLOTS_GROUP_ORDERING = [
  { key: 'general.ascending', value: 'asc' },
  { key: 'general.descending', value: 'desc' }
];

export const GROUP_APPOINTMENTS_FILTER_BEHAVIOUR_OPTIONS = [
  { display: 'AND', value: 'and' },
  { display: 'OR', value: 'or' }
];
export const GROUP_APPOINTMENTS_FILTER_BEHAVIOUR_TYPE = {
  AND: 'and',
  OR: 'or'
}

export const BOOKING_QUESTION_TYPE_PERMISSION = [
  {
    permissionRequired: 'is_select_booking_question_type_enabled',
    bookingQuestionType: 'select'
  },
  {
    permissionRequired: 'is_checkbox_booking_question_type_enabled',
    bookingQuestionType: 'checkbox'
  },
  {
    permissionRequired: 'is_textarea_booking_question_type_enabled',
    bookingQuestionType: 'textarea'
  },
  {
    permissionRequired: 'is_input_booking_question_type_enabled',
    bookingQuestionType: 'text'
  },
  {
    permissionRequired: 'is_upload_booking_question_type_enabled',
    bookingQuestionType: 'file'
  },
  {
    permissionRequired: 'is_radio_booking_question_type_enabled',
    bookingQuestionType: 'radio'
  },
  {
    permissionRequired: 'is_date_booking_question_type_enabled',
    bookingQuestionType: 'date'
  },
  {
    permissionRequired: 'is_information_booking_question_type_enabled',
    bookingQuestionType: 'information'
  },
  {
    permissionRequired: 'is_number_select_booking_question_type_enabled',
    bookingQuestionType: 'number-select'
  },
  {
    permissionRequired: 'is_image_select_booking_question_type_enabled',
    bookingQuestionType: 'image-select'
  },
  {
    permissionRequired: 'is_card_select_booking_question_type_enabled',
    bookingQuestionType: 'card-select'
  },
  {
    permissionRequired: 'is_worker_select_booking_question_type_enabled', // @TODO(Pratik): Verify feature name
    bookingQuestionType: 'worker-select'
  }
];

export const GDPR_DOC_LINK = "https://my.calenso.com/files/pdf/Calenso_DPA.pdf";

export const CALENDLY_AUTH_URL = 'https://auth.calendly.com/oauth/authorize';

export const SMART_ACTIONS_PERMISSION_REQUIRED = {
  1: 'is_thank_you_mail_after_appointment_smartaction_enabled',
  2: 'is_satisfaction_survey_smartaction_enabled',
  5: 'is_mass_cancellation_smartaction_enabled',
  6: 'is_monthly_group_appointment_participant_export_smartaction_enabled',
  7: 'is_mail_before_appointment_smartaction_enabled',
  8: 'is_monthly_export_smartaction_enabled'
};

export const CUSTOMER_CSTM_FIELD_TITLE_LIST = [
  { id: 0, name: '--' },
  { id: 1, name: 'Dr.' },
  { id: 2, name: 'Dr. med.' },
  { id: 3, name: 'Prof.' }
];

export const SUBSCRIPTION_CANCELLATION_REASONS = [
  { id: 1, name: 'technical_issue' },
  { id: 2, name: 'too_expensive' },
  { id: 3, name: 'switching_to_another_product' },
  { id: 4, name: 'company_shutdown' },
  { id: 5, name: 'missing_feature' },
  { id: 6, name: 'too_complicated' },
  { id: 7, name: 'other' }
];

export const NOTIFICATION_TYPE = [{
  display: 'email',
  value: 'email'
}, {
  display: 'sms',
  value: 'sms'
}, {
  display: 'both',
  value: 'both'
}];

export const CHILD_TO_PARENT_EDIT_EVENTS = {
  DESCRIPTION: 'PARTNER.DESCRIPTION',
  APPOINTMENT_TAB: 'BOOKING_PAGE_HIDE_APPOINTMENT_TAB',
  EVENT_TAB: 'BOOKING_PAGE_HIDE_EVENT_TAB',
  EMPLOYEE_TAB: 'BOOKING_PAGE_HIDE_EMPLOYEE_TAB',
  STORE_TAB: 'BOOKING_PAGE_HIDE_STORE_TAB',
  ABOUT_US_TAB: 'BOOKING_PAGE_HIDE_ABOUT_US_TAB',
  RATINGS_TAB: 'BOOKING_PAGE_HIDE_RATINGS_TAB',
  EDIT_BUSINESS_HOURS: 'PARTNER.BUSINESS_HOURS',
  CALENSO_EDIT_BOOKING_PAGE_FIELDS: 'CALENSO.EDIT_BOOKING_PAGE_FIELDS',
  PARTNER_SETTING: 'PARTNER.SETTINGS',
  PARTNER_BACKGROUND_IMAGE: 'PARTNER.BACKGROUND',
  PARTNER_LOGO: 'PARTNER.LOGO'
}

export const WIDGET_TYPES = [
  {
    key: 'startsite_component.appointments',
    value: APP_CONSTANTS.APPOINTMENT,
    permission: ['read:appointments', 'read:own:appointments', 'read:own:store:appointments']
  },
  {
    key: 'startsite_component.compact',
    value: APP_CONSTANTS.COMPACT,
    permission: ['read:appointments', 'read:own:appointments', 'read:own:store:appointments']
  },
  {
    key: 'startsite_component.group_appointments',
    value: APP_CONSTANTS.EVENT,
    permission: ['read:events', 'read:own:events', 'read:own:store:events']
  },
  {
    key: 'startsite_component.conference',
    value: APP_CONSTANTS.CONFERENCE,
    permission: ['read:booking-links'],
    feature: 'is_booking_links_feature_enabled'
  }
]

export const CALDAV_PROVIDERS = {
  infomaniak: 'Infomaniak',
  nextcloud: 'NextCloud',
  owncloud: 'OwnCloud',
  zimbra: 'Zimbra',
};

export const STATUS_TYPES = {
  failed: 'Failed',
  opened: 'Opened',
  sent: 'Sent'
};

export const LOCATION_DEPENDENCIES = [
  { label: 'inherit', value: '' },
  { label: 'true', value: true },
  { label: 'false', value: false }
];

export const EMAIL_SMS_CONST = {
  email: 'Email',
  sms: 'SMS'
};

export const AUTHY_APP_LINKS = {
  android: 'https://play.google.com/store/apps/details?id=com.authy.authy',
  ios: 'https://apps.apple.com/us/app/authy/id494168017'
};

export const CUSTOM_CSS_WIDGET_TYPES = [
  { key: 'wt_widget', value: 'widget' },
  { key: 'wt_smartwidget', value: 'smart_widget' },
  { key: 'wt_bookingpage', value: 'booking_page' },
];

export const UTILISATION_REPORT_RANGE_ENTITY = [
  { key: 'stores', value: 'utilization_report.store' },
  { key: 'workers', value: 'utilization_report.resource' },
];

export const VALID_IMAGE_FILE_TYPES = ['image/png', 'image/jpeg', 'image/gif', 'image/x-icon'];
export const VALID_EXCEL_FILE_TYPES = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
export const MAX_FILE_SIZE = 5 * 1024 * 1024;

export const MIME_TYPES = {
  IMAGE: 'image/jpeg,image/jpg,image/png,image/gif',
  DOC: 'application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

export const PREFERRED_COUNTRIES = ['ch', 'de', 'fr', 'it', 'nl', 'fi', 'pl', 'es', 'se', 'at'];

export const INTL_TEL_INPUT = {
  UTIL_SCRIPT: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/24.0.0/js/utils.js'
};

export const MAX_SLOTS_VISIBLE_LENGTH = 5;

export const BOOKINGPAGE_UI_MODES = [{ value: 'grid', display: 'general.grid' }, { value: 'list', display: 'general.list' }];

export const CURRENCY_SYMBOL = {
  CHF: 'CHF',
  EUR: 'EUR'
};

export const EVENT_SLOTS_LIMIT = 30;

export const JOB_QUEUE_RECORDS_LIMIT = 50;

export const MAX_LIMIT = {
  url_length: 2000,
  lead_time: 336,
  followup_time: 336,
  price: 99999999,
  duration: 1440,
  widgetConfigurations: 15,
  conferenceTitle: 255,
  conferenceDescription: 1000,
  bookingTypeNameMinLength: 2,
  bookingTypeNameMaxLength: 255,
  bookingTypeAttributeMinLength: 2,
  bookingTypeAttributeMaxLength: 255,
  resourceBookingTypeAttributesMinLength: 0,
  resourceBookingTypeAttributesMaxLength: 49999,
  appointmentServiceTitleMaxLength: 255,
  appointmentServiceDescriptionMaxLength: 500
}

export const EVENT_SLOT_TIME_DURATION: CalioSelectFormModel[] = [{
  value: 15,
  label: 'event_time_slots.xx_min',
  hideValue: false
}, {
  value: 30,
  label: 'event_time_slots.xx_min',
  hideValue: false
}, {
  value: 45,
  label: 'event_time_slots.xx_min',
  hideValue: false
}, {
  value: 60,
  label: 'event_time_slots.xx_min',
  hideValue: false
}];


export const EVENT_REPEATING_DAYS_LIST: CalioSelectFormModel[] = [{
  value: 30,
  label: 'event_time_slots.days',
  hideValue: false
}, {
  value: 60,
  label: 'event_time_slots.days',
  hideValue: false
}, {
  value: 90,
  label: 'event_time_slots.days',
  hideValue: false
}, {
  value: 365,
  label: 'event_time_slots.days',
  hideValue: false
}];

export const LANDING_PAGE_MODE = {
  admin: "admin",
  user: "user",
}

export const SECTION = {
  ADMIN_AREA: 'admin-area',
  BILLING: 'billing',
  NOTIFICATION: 'notification',
  SECURITY: 'security',
  RESOURCES: 'resources',
  ADVANCED: 'advanced',
  ANALYTICS: 'analytics',
  HELP_AND_SUPPORT: 'help-support',
  SETTINGS: 'settings'
}

export enum THEME {
  OLD,
  NEW
}

export const UI_VIEW_MODE = {
  MOBILE: "MOBILE",
  TABLET: "TABLET",
  LAPTOP: "LAPTOP"
}

export const EMAIL_PROVIDERS_MAPPING = {
  smtp: 'SMTP',
  mailpit: 'Mailpit',
  sendinblue: 'Brevo',
  brevo: 'Brevo'
}

export const APPOINTMENT_TYPE = {
  LEAD: 'lead',
  FOLLOWUP: 'followup',
} as const;

export const RESELLER_WHITELIST_PATH = ['appointments', 'events', 'customers', 'settings'];

export const CHART_COLORS = ['#FFC0CB', '#5555FF', '#68D6F0', '#4CAF50', '#EF6565', '#FFCB3D', '#5555FF', '#4285F4', '#EF6565', '#96CB2E', '#0DCAF0', '#000000', '#3A4A66', '#909AAF', '#FF8000', '#F62372', '#F6BE23', '#9423F6', '#9C5834', '#988F16', '#00BCA3', '#BC00BC', '#488B9F', '#06651F'];

export const ANALYTICS_FILTER_TYPE = {
  YEAR: 'year',
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day'
};

export const MONTH_DAY_LIST = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
