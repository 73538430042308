import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { EventEmitter, inject, Injectable } from '@angular/core';
import { EmployeeFormModel, ServiceWorkerModel } from '@client-models/employee-form.model';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { loginDbModel } from '@db-models/login-db.model';
import { MfaDbModel, MfaLocalStorageModel, MfaVerifyResetDbModel } from '@db-models/mfa.models';
import { AuthRole, ServiceWorkerDbModel, WorkerDbModel } from '@db-models/worker-db.model';
import { CryptoService } from '@util-services/crypto.service';
import { HelperService } from '@util-services/helper.service';
import { HttpClientService } from '@util-services/http-client.service';
import { LocalStorageService } from '@util-services/local-storage.service';
import { LoggerService } from '@util-services/logger.service';
import { Observable, of } from 'rxjs';
import { catchError, share, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {
  onWorkerSelectionChanged = new EventEmitter<WorkerDbModel>();
  onWorkerIdsSelectionChanged = new EventEmitter<number[]>();
  onWorkerDetailsChanged = new EventEmitter<WorkerDbModel>();

  mfaToken: string;
  private httpClientService = inject(HttpClientService);
  private localstorageService = inject(LocalStorageService);
  private cryptoService = inject(CryptoService);
  private helperService = inject(HelperService);

  importWorkersExcel(file: File, partnerId: number): Observable<WorkerDbModel[]> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('partner_id', partnerId + '');
    return this.httpClientService.post('workers/import', formData, {});
  }

  getWorkersByPagination(limit: number, offset: number): Observable<WorkerDbModel[]> {
    const params = new HttpParams()
      .set('limit', limit + '')
      .set('offset', offset + '');

    return this.httpClientService.get('workers', { params: params }).pipe(
      switchMap((workers: WorkerDbModel[]) => {
        if (!workers?.length) {
          return of([]);
        }

        workers?.forEach(worker => {
          if (worker?.services_workers?.length) {
            worker.services_workers = worker.services_workers.filter(service => service.appointment_service !== null);
          }
        });

        return of(workers);
      })
    );
  }

  searchWorkers(query: string, storeId: number, limit: number, offset: number): Observable<WorkerDbModel[]> {
    const params = new HttpParams().set('limit', limit + '').set('offset', offset + '');
    const body = {
      ...query && { 'query': query },
      ...storeId && { 'store_id': storeId }
    };
    return this.httpClientService.post(`workers/search`, body, { params });
  }

  getWorkers(): Observable<WorkerDbModel[]> {
    const workers: WorkerDbModel[] = this.getWorkersFromLocalStorage();
    if (workers) {
      workers.forEach(worker => {
        if (worker?.services_workers?.length) {
          worker.services_workers = worker.services_workers.filter(service => service.appointment_service !== null);
        }
      });

      return of(workers);
    }

    return this.workerList$.pipe(switchMap((worker) => of(worker).pipe(take(1))));
  }

  workerList$ = new Observable((observer) => {
    // Simulate an HTTP request
    this.httpClientService.get('workers', {}).pipe(
      switchMap((workers: WorkerDbModel[]) => {
        workers?.forEach(worker => {
          !worker.calendar_color && (worker.calendar_color = this.helperService.getRandomColor());
          if (worker?.services_workers?.length) {
            worker.services_workers = worker.services_workers.filter(service => service.appointment_service !== null);
          }
        });

        return of(workers);
      })
    ).subscribe({
      next: workers => {
        observer.next(workers);
        observer.complete();
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
        observer.error(error);
        observer.complete();
      }
    });
  }).pipe(
    // Share the response and replay the last emitted value to new subscribers
    share()
  ) as Observable<WorkerDbModel[]>;

  getLoggedInWorkerFromLocalStorage(): WorkerDbModel {
    const encryptedWorker = this.localstorageService.get(LOCAL_STORAGE_CONSTANT.LOGGED_IN_WORKER);
    if (encryptedWorker) {
      const decryptedWorkers = this.cryptoService.decryptValue(encryptedWorker);
      return JSON.parse(decryptedWorkers) as WorkerDbModel;
    } else {
      return null;
    }
  }

  setLoggedInUserInLocalStorage(workerUuid = ''): Observable<WorkerDbModel> {
    return this.filterWorkers(workerUuid).pipe(
      switchMap(workers => {
        let foundedWorker: WorkerDbModel = null;
        if (workers?.length === 1) {
          foundedWorker = workers[0];
        } else if (workers?.length > 1) {
          foundedWorker = workers.find(worker => worker.uuid === workerUuid);
        }

        if (foundedWorker) {
          const encryptedString = this.cryptoService.encryptValue(JSON.stringify(foundedWorker));
          this.localstorageService.set(LOCAL_STORAGE_CONSTANT.LOGGED_IN_WORKER, encryptedString);
        }

        return of(foundedWorker);
      }),
      catchError((error: HttpErrorResponse) => {
        LoggerService.error(error);
        return of(null);
      })
    );
  }

  filterWorkers(uuid: string) {
    const body = {
      uuid: {
        value: uuid,
        operator: "="
      }
    }
    return this.httpClientService.post(`workers/filter`, body, {}).pipe(
      switchMap((workers: WorkerDbModel[]) => workers ? of(workers) : of([])),
      catchError((error: HttpErrorResponse) => {
        LoggerService.error(error);
        return of([]);
      })
    );
  }

  getWorkerById(uuid: string): Observable<WorkerDbModel> {
    return this.httpClientService.get(`workers/${uuid}`, {}).pipe(
      switchMap((worker: WorkerDbModel) => {
        if (worker?.services_workers?.length) {
          worker.services_workers = worker.services_workers.filter(service => service.appointment_service !== null);
        }
        return of(worker);
      })
    );
  }

  saveWorker(employeeFormModel: EmployeeFormModel): Observable<WorkerDbModel> {
    this.clearWorkersFromLocalStorage();
    if (employeeFormModel.id) {
      return this.editWorker(employeeFormModel);
    } else {
      return this.createWorker(employeeFormModel);
    }
  }

  createWorker(employeeFormModel: EmployeeFormModel): Observable<WorkerDbModel> {
    employeeFormModel['error_toast_in_interceptor'] = false;
    return this.httpClientService.post('workers', employeeFormModel, {});
  }

  editWorker(employeeFormModel: EmployeeFormModel): Observable<WorkerDbModel> {
    const loggedInWorker = this.getLoggedInWorkerFromLocalStorage();
    if (employeeFormModel?.uuid) {
      return this.httpClientService.put(`workers/${employeeFormModel.uuid}`, employeeFormModel, {}).pipe(
        switchMap(worker => {
          if (loggedInWorker.uuid === worker.uuid) {
            const encryptedString = this.cryptoService.encryptValue(JSON.stringify(worker));
            this.localstorageService.set(LOCAL_STORAGE_CONSTANT.LOGGED_IN_WORKER, encryptedString);
          }
          return of(worker);
        })
      );
    } else {
      LoggerService.warn('Failed to edit worker as uuid is missing', employeeFormModel);
      return null;
    }
  }

  setLanguage(workerId: number, language: string): Observable<WorkerDbModel> {
    const body = {
      worker_id: workerId,
      lang_identifier: language
    };
    return this.httpClientService.post(`workers/language`, body, {}).pipe(
      switchMap((result: { worker: WorkerDbModel }) => {
        return of(result.worker);
      })
    );
  }

  saveServiceWorkers(serviceWorkerModel: ServiceWorkerModel): Observable<{ skills: ServiceWorkerDbModel[] }> {
    return this.httpClientService.post(`services_workers`, serviceWorkerModel, {});
  }

  deleteWorker(uuid: string): Observable<{ success: boolean }> {
    this.clearWorkersFromLocalStorage();
    return this.httpClientService.delete(`workers/${uuid}`, { body: { error_toast_in_interceptor: false } });
  }

  getLimitedWorkersList(limit: number = null, offset: number = null): Observable<WorkerDbModel[]> {
    let params = new HttpParams();

    if (limit !== null && offset !== null) {
      params = new HttpParams()
        .set('limit', limit + '')
        .set('offset', offset + '');
    }

    if (limit === null && offset === null) {
      const workers = this.getShortWorkersFromLocalStorage();
      if (workers) {
        return of(workers);
      }
    }

    return this.httpClientService.get(`workers/short`, { params }).pipe(
      switchMap(workers => {
        for (const worker of workers) {
          if (!worker.calendar_color) {
            worker.calendar_color = this.helperService.getRandomColor();
          }
        }

        (limit === null && offset === null) && this.setShortWorkersInLocalStorage(workers);
        return of(workers);
      })
    );
  }

  updateSingleWorkerInLocalStorage(worker: WorkerDbModel) {
    const workers = this.getWorkersFromLocalStorage();
    if (workers) {
      const foundIndex: number = workers.findIndex(x => x.id === worker.id);
      workers[foundIndex] = worker;
      this.setWorkersInLocalStorage(workers);
    }
  }

  setShortWorkersInLocalStorage(workers: WorkerDbModel[]): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(workers)
    );
    this.localstorageService.set(LOCAL_STORAGE_CONSTANT.SHORT_WORKER_LIST_KEY, encryptedString);
  }

  getShortWorkersFromLocalStorage(): WorkerDbModel[] {
    const encryptedWorkers: string = this.localstorageService.get(LOCAL_STORAGE_CONSTANT.SHORT_WORKER_LIST_KEY);
    if (encryptedWorkers) {
      const decryptedWorkers: string = this.cryptoService.decryptValue(encryptedWorkers);
      const workers: WorkerDbModel[] = JSON.parse(decryptedWorkers);
      return workers;
    } else {
      return null;
    }
  }

  setWorkersInLocalStorage(workers: WorkerDbModel[]): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(workers)
    );
    this.localstorageService.set(LOCAL_STORAGE_CONSTANT.WORKERS_KEY, encryptedString);
  }

  getWorkersFromLocalStorage(): WorkerDbModel[] {
    const encryptedWorkers: string = this.localstorageService.get(LOCAL_STORAGE_CONSTANT.WORKERS_KEY);
    if (encryptedWorkers) {
      const decryptedWorkers: string = this.cryptoService.decryptValue(encryptedWorkers);
      const workers: WorkerDbModel[] = JSON.parse(decryptedWorkers);
      return workers;
    } else {
      return null;
    }
  }

  createClientWorkerFormModel(workerDbModel: WorkerDbModel): EmployeeFormModel {
    const employeeFormModel: EmployeeFormModel = new EmployeeFormModel();
    employeeFormModel.id = workerDbModel.id;
    employeeFormModel.prename = workerDbModel.prename;
    employeeFormModel.lastname = workerDbModel.lastname;
    employeeFormModel.username = workerDbModel.username;
    employeeFormModel.booking_label = workerDbModel.booking_label;
    employeeFormModel.description = workerDbModel.description;
    employeeFormModel.email = workerDbModel.email;
    employeeFormModel.job_title = workerDbModel.job_title;
    employeeFormModel.conference_url = workerDbModel.conference_url;
    employeeFormModel.store_id = workerDbModel.store_id;
    employeeFormModel.bookable = !!(workerDbModel.bookable === 1);
    employeeFormModel.show_in_pro_calendar = !!(workerDbModel.show_in_pro_calendar === 1);
    employeeFormModel.calendar_color = workerDbModel.calendar_color;
    return employeeFormModel;
  }

  clearWorkersFromLocalStorage() {
    this.localstorageService.remove(LOCAL_STORAGE_CONSTANT.WORKERS_KEY);
    this.localstorageService.remove(LOCAL_STORAGE_CONSTANT.SHORT_WORKER_LIST_KEY);
  }

  uploadAvatar(file: File, relativePath: string, uuid: string): Observable<{ worker: WorkerDbModel }> {
    const formData: FormData = new FormData();
    relativePath = relativePath.replace('.', Math.floor(Math.random() * 10000) + '.');
    formData.append('avatar', file, relativePath);
    return this.httpClientService.post(`workers/avatar/${uuid}`, formData, {});
  }

  deleteWorkerSkill(id: number): Observable<{ success: boolean }> {
    return this.httpClientService.delete(`services_workers/${id}`, {});
  }

  updateWorker(worker: WorkerDbModel): Observable<WorkerDbModel> {
    return this.httpClientService.put(`workers/${worker.uuid}`, worker, {});
  }

  resetWorkerPassword(email: string): Observable<{ success: boolean }> {
    const body: { [key: string]: string } = { email };
    return this.httpClientService.post(`workers/reset`, body, {});
  }

  changeWorkerPassword(body: {
    worker_id: number,
    old_password: string,
    password: string,
    password_repeat: string
  }): Observable<{ success: boolean }> {
    return this.httpClientService.post(`workers/password`, body, {});
  }

  createNewWorkerPassword(body: {
    worker_id: number,
    token: string,
    password: string,
    password_repeat: string
  }): Observable<{ success: boolean }> {
    return this.httpClientService.post(`workers/new_password`, body, {});
  }

  loginAsResource(partner_uuid: string, worker_uuid: string): Observable<{ success: boolean }> {
    const body = { partner_uuid, worker_uuid };
    return this.httpClientService.post(`workers/partnerAuth`, body, {});
  }

  setAnonymousUserInLocalStorage(user: { name: string, email: string }) {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(user)
    );
    this.localstorageService.set(LOCAL_STORAGE_CONSTANT.ANONYMOUS_USER_CREDENTIALS, encryptedString);
  }

  getAnonymousUserFromLocalStorage(): { name: string, email: string } {
    const encrypted: string = this.localstorageService.get(LOCAL_STORAGE_CONSTANT.ANONYMOUS_USER_CREDENTIALS);
    if (encrypted) {
      const decryptedWorkers: string = this.cryptoService.decryptValue(encrypted);
      const user: { name: string, email: string } = JSON.parse(decryptedWorkers);
      return user;
    } else {
      return null;
    }
  }

  getWorkerAvatarFromLocalStorage(): string {
    return this.localstorageService.get(LOCAL_STORAGE_CONSTANT.AVATAR_KEY);
  }

  getRoles(): Observable<AuthRole[]> {
    return this.httpClientService.get(`roles`, {});
  }

  loginAsReseller(partnerId: number): Observable<{ success: boolean }> {
    const body: { partner_id: number } = { partner_id: partnerId };
    return this.httpClientService.post(`workers/reseller_auth`, body, {});
  }

  resetResellerAdmin(): Observable<{ success: boolean }> {
    return this.httpClientService.post(`workers/reseller_logout`, {}, {});
  }

  enableWorkerMfa(workerUuid: string): Observable<MfaDbModel> {
    return this.httpClientService.authServicePost(
      'workers/generate-mfa-secret',
      { error_toast_in_interceptor: false, worker_uuid: workerUuid },
      {}
    );
  }

  resetWorkerMfa(code: string, workerUuid: string): Observable<MfaVerifyResetDbModel> {
    return this.httpClientService.authServiceDelete(
      'workers/mfa',
      { body: { code, worker_uuid: workerUuid } }
    );
  }

  verifyWorkeMfa(param: { code: string; token: string }): Observable<loginDbModel> {
    return this.httpClientService.authServicePost(
      'auth/verify-mfa',
      param,
      {}
    );
  }

  addWorkerMfa(code: string): Observable<MfaVerifyResetDbModel> {
    return this.httpClientService.authServicePost(
      'workers/mfa',
      { code, token: this.mfaToken },
      {}
    );
  }

  newVerifyWorkeMfa(code: string): Observable<MfaVerifyResetDbModel> {
    return this.httpClientService.authServicePost(
      'auth/verify-mfa-code',
      { code, reset: false },
      {}
    );
  }

  setMfaVerificationDetailsInLocalStorage(): void {
    const mfaData: MfaLocalStorageModel = {
      verified: true,
      date: new Date().toISOString()
    };
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(mfaData)
    );
    this.localstorageService.set(LOCAL_STORAGE_CONSTANT.MFA, encryptedString);
  }

  getMfaVerificationDetailsInLocalStorage(): MfaLocalStorageModel | null {
    const encrypted = this.localstorageService.get(LOCAL_STORAGE_CONSTANT.MFA);
    if (encrypted) {
      const decryptedMfa = this.cryptoService.decryptValue(encrypted);
      const mfaData: {
        verified: boolean,
        date: string
      } = JSON.parse(decryptedMfa);
      return mfaData;
    }
    return null;
  }

  getSupportUsers(): Observable<WorkerDbModel[]> {
    const body = { is_support_user: { value: 1, operator: "=" } };
    return this.httpClientService.post(`workers/filter`, body, {}).pipe(
      switchMap((workers: WorkerDbModel[]) => workers ? of(workers) : of([])),
      catchError((error: HttpErrorResponse) => {
        LoggerService.error(error);
        return of([]);
      })
    );
  }
}
