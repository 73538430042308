import { HttpContext, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ConfigValidationModel } from '@client-models/config-validation.model';
import { PartnerValidationModel } from '@client-models/partner-validation-data.model';
import { DigitizationSuiteFormModel } from '@client-models/request-digitalization-suite.model';
import { APP_CONSTANTS } from '@constants/app.constants';
import { LANDING_PAGE_AUTH_REQUIRED } from '@constants/http-context-token';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { CountryDbModel } from '@db-models/country.model';
import { IPartnerClientId, PartnerDbModel, PartnerTranslationForm, SearchPartnersDbModel } from '@db-models/partner-db.model';
import { SetupAssistantStatusModel } from '@db-models/partnership-db.model';
import { SubscriptionModel } from '@db-models/subscribe.model';
import { WorkerDbModel } from '@db-models/worker-db.model';
import { FeatureService } from '@feature-services/feature.service';
import { TranslateService } from '@ngx-translate/core';
import { CryptoService } from '@util-services/crypto.service';
import { HelperService } from '@util-services/helper.service';
import { HttpClientService } from '@util-services/http-client.service';
import { LocalStorageService } from '@util-services/local-storage.service';
import { LoggerService } from '@util-services/logger.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  partnerChangeEvent = new EventEmitter<PartnerDbModel>();
  partnerLoadEvent = new EventEmitter<PartnerDbModel>();
  betaFlagToggleEvent = new EventEmitter<number>();

  constructor(
    private httpClientService: HttpClientService,
    private localStorageService: LocalStorageService,
    private cryptoService: CryptoService,
    public helperService: HelperService,
    public translate: TranslateService,
    private featureService: FeatureService
  ) { }

  updatePartnerRoles(partnerRoleModel: { partner_id: number, role_ids: number[] }): Observable<any> {
    return this.httpClientService.post('partners/roles', partnerRoleModel, {});
  }

  getPartners(): Observable<any> {
    return this.httpClientService.get('partners', {});
  }

  searchPartners(query: string): Observable<PartnerDbModel[]> {
    const body: { query: string } = { query };
    return this.httpClientService.post(`partners/search`, body, {});
  }

  getPartnerDashboard(limit: number, offset: number): Observable<SearchPartnersDbModel> {
    const params = new HttpParams()
      .set('limit', limit + '')
      .set('offset', offset + '');

    return this.httpClientService.get(`partners/dashboard`, { params });
  }

  getPartnersByName(name: string): Observable<any> {
    const body = { name: { value: name, operator: 'like' } };
    return this.httpClientService.post('partners/filter', body, {});
  }

  validatePartner(partnerValidationModel: PartnerValidationModel): Observable<{ isPartnerValid: PartnerValidationModel }> {
    return this.httpClientService.post('partners/validation', partnerValidationModel, {});
  }

  createPartner(partnerDbModel: PartnerDbModel) {
    return this.httpClientService.post('partners', partnerDbModel, {});
  }

  editPartner(partnerDbModel: PartnerDbModel): Observable<{ partner: PartnerDbModel }> {
    const partnerUuid = partnerDbModel.uuid;
    const partnerDbModelBody = Object.assign({}, partnerDbModel);

    return this.httpClientService.put(`partners/${partnerUuid}`, partnerDbModelBody, {}).pipe(
      switchMap((result: { partner: PartnerDbModel }) => {
        this.setPartnerInLocalStorage(result.partner);
        return of(result);
      })
    );
  }

  loadPartnerDataAfterLogin(partnerId: number): void {
    this.httpClientService.get(`partners/${partnerId}`, {}).subscribe({
      next: (result: { partner: PartnerDbModel }) => {
        this.setPartnerInLocalStorage(result.partner);
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  validateConfig(): Observable<ConfigValidationModel> {
    return this.httpClientService.get('partners/validate_config', {}).pipe(
      switchMap((result: ConfigValidationModel) => {
        this.setConfigValidationInLocalStorage(result);
        return of(result);
      })
    );
  }

  initializeDashboard(): Observable<{ partner: PartnerDbModel }> {
    return this.httpClientService.get('workers/initialize_dashboard', {}).pipe(
      switchMap((result: { partner: PartnerDbModel }) => {
        this.setPartnerInLocalStorage(result.partner);
        this.setPartnerCountryInLocalStorage(result.partner.country);
        this.featureService.setFeatureInLocalStorage(result.partner.subscription);
        this.setSubscriptionDetailInLocalStorage(result.partner.subscription);
        return of(result);
      })
    );
  }

  getPartner(partnerId: string, refresh?: boolean): Observable<PartnerDbModel> {
    if (!refresh) {
      const partner = this.getPartnerFromLocalStorage();
      if (partner) {
        return of(partner);
      }
    }

    return this.httpClientService.get(`partners/${partnerId}`, {}).pipe(
      switchMap((result: { partner: PartnerDbModel }) => {
        this.setPartnerInLocalStorage(result.partner);
        return of(result.partner);
      })
    );
  }

  setLangInLocalStorage(partner: PartnerDbModel, loggedInWorker: WorkerDbModel) {
    const localStorageLang: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY);
    const finalLang: string = this.helperService.getDefaultLanguage(localStorageLang, partner, loggedInWorker);
    this.translate.setDefaultLang(finalLang);
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY, finalLang);
  }

  uploadLogo(file: File, relativePath: string): Observable<{ partner: PartnerDbModel }> {
    const formData: FormData = new FormData();
    relativePath = relativePath.replace('.', Math.floor(Math.random() * 10000) + '.');
    formData.append('logo', file, relativePath);
    return this.httpClientService.post('partners/image', formData, {});
  }

  uploadLandingPageLogo(file: File, relativePath: string): Observable<{ partner: PartnerDbModel }> {
    const formData: FormData = new FormData();
    relativePath = relativePath.replace('.', Math.floor(Math.random() * 10000) + '.');
    formData.append('landingpage_logo', file, relativePath);
    return this.httpClientService.post('partners/image', formData, {});
  }

  uploadFaviconLogo(file: File, relativePath: string): Observable<{ partner: PartnerDbModel }> {
    const formData: FormData = new FormData();
    relativePath = relativePath.replace('.', Math.floor(Math.random() * 10000) + '.');
    formData.append('favicon_logo', file, relativePath);
    return this.httpClientService.post('partners/image', formData, {});
  }

  uploadPartnerEmailImage(file: File, relativePath: string): Observable<{ partner: PartnerDbModel }> {
    const formData: FormData = new FormData();
    relativePath = relativePath.replace('.', Math.floor(Math.random() * 10000) + '.');
    formData.append('email_logo', file, relativePath);
    return this.httpClientService.post('partners/image', formData, {});
  }

  uploadPartnerBookingPageBackgroundImage(
    file: File, relativePath: string
  ): Observable<{ partner: PartnerDbModel }> {
    const formData: FormData = new FormData();
    relativePath = relativePath.replace('.', Math.floor(Math.random() * 10000) + '.');
    formData.append('booking_page_background_logo', file, relativePath);
    return this.httpClientService.post('partners/image', formData, {});
  }

  setPartnerInLocalStorage(partner: PartnerDbModel): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(partner)
    );
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.PARTNER_KEY, encryptedString);
  }

  setConfigValidationInLocalStorage(configValidation: ConfigValidationModel): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(configValidation)
    );
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.CONFIG_VALIDATION, encryptedString);
    LoggerService.log('config validation saved');
  }

  getPartnerFromLocalStorage(): PartnerDbModel {
    const encryptedPartner: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.PARTNER_KEY);
    if (encryptedPartner) {
      const decryptedPartner: string = this.cryptoService.decryptValue(encryptedPartner);
      const partner: PartnerDbModel = Object.assign({}, JSON.parse(decryptedPartner));
      if (partner.internal_token === undefined) {
        this.initializeDashboard().subscribe(partner => {
          return partner;
        });
      } else {
        if (!partner.supported_widget_languages) {
          partner.supported_widget_languages = [];
        }
        return partner;
      }
    } else {
      return null;
    }
  }

  setSubscriptionDetailInLocalStorage(subscription: SubscriptionModel): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(subscription)
    );
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.SUBSCRIPTION_DETAIL, encryptedString);
  }

  getSubscriptionDetailFromLocalStorage(): SubscriptionModel {
    const encrypted: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.SUBSCRIPTION_DETAIL);
    if (encrypted) {
      const decrypted: string = this.cryptoService.decryptValue(encrypted);
      const subscription: SubscriptionModel = Object.assign({}, JSON.parse(decrypted));
      return subscription;
    } else {
      return null;
    }
  }

  getConfigValidationFromLocalStorage(): ConfigValidationModel {
    const encrypted: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.CONFIG_VALIDATION);
    if (encrypted) {
      const decrypted: string = this.cryptoService.decryptValue(encrypted);
      const configValidation: ConfigValidationModel = Object.assign({}, JSON.parse(decrypted));
      return configValidation;
    } else {
      return null;
    }
  }

  getPartnerUuid(): string {
    const partnerDb = this.getPartnerFromLocalStorage();
    return partnerDb?.uuid || null;
  }

  updateTranslationInfoInPartner(partnerTranslationForm: PartnerTranslationForm, partner: PartnerDbModel, currentLang: string) {
    partner.is_multi_language = partnerTranslationForm.is_multi_language ? 1 : 0;
    APP_CONSTANTS.LANGUAGES.forEach(language => {
      if (partner.is_multi_language) {
        if (language.locale === currentLang) {
          partner.micro_partner_description = partnerTranslationForm['formControl_' + language.value].description;
        }
        partner['micro_partner_description_' + language.value] = partnerTranslationForm['formControl_' + language.value].description;
      } else {
        partner.micro_partner_description = partnerTranslationForm.formControl_default.description;
        partner['micro_partner_description_' + language.value] = undefined;
      }
    });
  }

  fixPartnerData(
    partnerDbModel: PartnerDbModel,
    useLandingPageAuth = false,
    authTokenUuid = ''
  ): Observable<{ partner: PartnerDbModel }> {
    const partnerId = partnerDbModel.uuid;
    delete partnerDbModel.id;
    const partnerDbModelBody: PartnerDbModel = Object.assign({}, partnerDbModel);

    let context: HttpContext;
    if (useLandingPageAuth) {
      context = new HttpContext().set(LANDING_PAGE_AUTH_REQUIRED, authTokenUuid);
    }

    return this.httpClientService.put(`partners/${partnerId}`, partnerDbModelBody, { context }).pipe(
      switchMap((result: { partner: PartnerDbModel }) => {
        return of(result);
      })
    );
  }

  requestDigitalizationSuite(payload: DigitizationSuiteFormModel): Observable<any> {
    return this.httpClientService.post('partners/request_digitalization_suite', payload, {});
  }

  getSetupAssistantStatus(): Observable<SetupAssistantStatusModel> {
    return this.httpClientService.get('partners/setup_assistant_status', {});
  }

  getPartnerClientIds(type: string, query: string, bookingName: string = undefined): Observable<IPartnerClientId[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("type", type);
    query && (queryParams = queryParams.append("query", query));
    bookingName && (queryParams = queryParams.append("booking_name", bookingName));

    return this.httpClientService.authServiceGet(`partners/client-ids`, {
      params: queryParams
    });
  }

  setPartnerCountryInLocalStorage(country: CountryDbModel): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(country)
    );
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.CALENSO_PARTNER_COUNTRY, encryptedString);
    LoggerService.log('partner country saved');
  }

  getPartnerCountryFromLocalStorage(): CountryDbModel {
    const encryptedPartner: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.CALENSO_PARTNER_COUNTRY);
    if (encryptedPartner) {
      const decryptedPartner: string = this.cryptoService.decryptValue(encryptedPartner);
      const partner: CountryDbModel = Object.assign({}, JSON.parse(decryptedPartner));
      return partner;
    } else {
      return null;
    }
  }

  blockPartner(partnerUuid: string): Observable<{ success: boolean }> {
    return this.httpClientService.post('partners/block', { partner_uuid: partnerUuid }, {});
  }

  approvePartnerRegistrationRequest(partnerUuid: string): Observable<{ success: boolean }> {
    return this.httpClientService.post('partners/approve', { partner_uuid: partnerUuid }, {});
  }
}
