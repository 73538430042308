import { Injectable, Type } from '@angular/core';
import { RebookAppointmentDialogComponent } from '@appointments/rebook-appointment-dialog/rebook-appointment-dialog.component';
import { AbsensesFormComponent } from '@calio-dynamic-lib/absences-forms/absenses-form/absenses-form.component';
import { AppointmentServicesFormComponent } from '@calio-dynamic-lib/appointment-forms/appointment-services-form/appointment-services-form.component';
import { AppointmentServicesQuestionsComponent } from '@calio-dynamic-lib/appointment-forms/appointment-services-questions/appointment-services-questions.component';
import { CreateServiceCategoryComponent } from '@calio-dynamic-lib/appointment-forms/create-service-category/create-service-category.component';
import { AvailabilitiesFormSwitcherComponent } from '@calio-dynamic-lib/availability-forms/availabilities-form-switcher/availabilities-form-switcher.component';
import { BookingQuestionOrderingComponent } from '@calio-dynamic-lib/booking-question-ordering/booking-question-ordering.component';
import { BryntumEventEditorComponent } from '@calio-dynamic-lib/bryntum-event-forms/bryntum-event-editor/bryntum-event-editor.component';
import { CalendarSetupComponent } from '@calio-dynamic-lib/calendar-setup/calendar-setup.component';
import { CalensoWidgetModalComponent } from '@calio-dynamic-lib/calenso-widget-modal/calenso-widget-modal.component';
import { CalioCreateCustomerModalFormComponent } from '@calio-dynamic-lib/calio-create-customer-modal-form/calio-create-customer-modal-form.component';
import { CalioPermissionModalComponent } from '@calio-dynamic-lib/calio-permission-modal/calio-permission-modal.component';
import { CompanySettingTranslationFormComponent } from '@calio-dynamic-lib/company-setting-translation/company-setting-translation-form/company-setting-translation-form.component';
import { ConfirmationModalComponent } from '@calio-dynamic-lib/confirmation-modal/confirmation-modal.component';
import { CustomFieldMappedServicesComponent } from '@calio-dynamic-lib/custom-field-mapped-services/custom-field-mapped-services.component';
import { CustomFormFieldHistoryComponent } from '@calio-dynamic-lib/custom-form-field-history/custom-form-field-history.component';
import { CustomerNotesFormComponent } from '@calio-dynamic-lib/customer-notes-form/customer-notes-form.component';
import { DeleteAccountConfirmationModalComponent } from '@calio-dynamic-lib/delete-account-confirmation-modal/delete-account-confirmation-modal.component';
import { DeleteEventConfirmationModalComponent } from '@calio-dynamic-lib/delete-event-confirmation-modal/delete-event-confirmation-modal.component';
import { EmployeeFormDialogComponent } from '@calio-dynamic-lib/employee-form/employee-form-dialog/employee-form-dialog.component';
import { ImageUploadDialogComponent } from '@calio-dynamic-lib/image-upload-dialog/image-upload-dialog.component';
import { JsonDiffViewerComponent } from '@calio-dynamic-lib/json-diff-viewer/json-diff-viewer.component';
import { JsonViewerComponent } from '@calio-dynamic-lib/json-viewer/json-viewer.component';
import { LandingpageBookingQuestionsFormComponent } from '@calio-dynamic-lib/landingpage-booking-questions-form/landingpage-booking-questions-form.component';
import { ManageBookingHoursComponent } from '@calio-dynamic-lib/manage-booking-hours/manage-booking-hours.component';
import { MeetingSetupComponent } from '@calio-dynamic-lib/meeting-setup/meeting-setup.component';
import { PartnerSettingModalComponent } from '@calio-dynamic-lib/partner-setting-modal/partner-setting-modal.component';
import { QrCodeDialogComponent } from '@calio-dynamic-lib/qr-code-dialog/qr-code-dialog.component';
import { QuestionFieldsFormComponent } from '@calio-dynamic-lib/question-fields/question-fields-form/question-fields-form.component';
import { PartnerReleaseNotesListComponent } from '@calio-dynamic-lib/release-notes/partner-release-notes-list/partner-release-notes-list.component';
import { ReleaseNotesSettingsFormComponent } from '@calio-dynamic-lib/release-notes/release-notes-settings-form/release-notes-settings-form.component';
import { SmartWidgetEmbedCodeDialogComponent } from '@calio-dynamic-lib/smart-widget-embed-code-dialog/smart-widget-embed-code-dialog.component';
import { SmartWidgetFormComponent } from '@calio-dynamic-lib/smart-widget-form/smart-widget-form.component';
import { SmartWidgetStepFormComponent } from '@calio-dynamic-lib/smart-widget-step-form/smart-widget-step-form.component';
import { StoresFormComponent } from '@calio-dynamic-lib/store-form/stores-form/stores-form.component';
import { StripeCardDialogComponent } from '@calio-dynamic-lib/stripe-card-dialog/stripe-card-dialog.component';
import { TemplatesFormDialogComponent } from '@calio-dynamic-lib/template-form-dialog/templates-form-dialog/templates-form-dialog.component';
import { TemplatesRestrictionFormComponent } from '@calio-dynamic-lib/template-form-dialog/templates-restriction-form/templates-restriction-form.component';
import { ApiErrorDetailsDialogComponent } from '@calio-dynamic-lib/third-party-api-error-details-dialog/third-party-api-error-details-dialog.component';
import { WebhookComponent } from '@calio-dynamic-lib/webhook/webhook.component';
import { WidgetAppointmentGroupFormComponent } from '@calio-dynamic-lib/widget-appointment-group/widget-appointment-group-form/widget-appointment-group-form.component';
import { CustomerLinksComponent } from '@dashboard/conference-dashboard/customer-links/customer-links.component';
import { GenerateQrCodeComponent } from '@dashboard/conference-dashboard/generate-qr-code/generate-qr-code.component';
import { WorkersListDialogComponent } from '@dashboard/conference-dashboard/workers-list-dialog/workers-list-dialog.component';
import { ManageSubscriptionComponent } from '@dashboard/crm-dashboard/common/customer-admin-section/manage-subscription/manage-subscription.component';
import { SupportLoginDialogComponent } from '@dashboard/crm-dashboard/common/customer-admin-section/support-login-dialog/support-login-dialog.component';
import { CustomerBookingDeleteConfirmationDialogComponent } from '@dashboard/crm-dashboard/common/customer-booking-delete-confirmation-dialog/customer-booking-delete-confirmation-dialog.component';
import { CreateFormComponent } from '@dashboard/crm-dashboard/common/manage-partners-settings/create-form/create-form.component';
import { MergeCustomersComponent } from '@dashboard/crm-dashboard/common/merge-customers/merge-customers.component';
import { ResellerInviteDialogComponent } from '@dashboard/dashboard-common/reseller-feature/reseller-invite-dialog/reseller-invite-dialog.component';
import { DealsFormComponent } from '@dashboard/deals-dashboard/deals-form/deals-form.component';
import { EventAddSlotBottomsheetComponent } from '@dashboard/events-dashboard/common/event-add-slot-bottomsheet/event-add-slot-bottomsheet.component';
import { EventCopyCodeDialogComponent } from '@dashboard/events-dashboard/common/event-copy-code-dialog/event-copy-code-dialog.component';
import { EventSlotEditDialogComponent } from '@dashboard/events-dashboard/common/event-slot-edit-dialog/event-slot-edit-dialog.component';
import { EventSlotParticipantComponent } from '@dashboard/events-dashboard/common/event-slot-participant/event-slot-participant.component';
import { SortMeetingProviderComponent } from '@dashboard/meeting-dashboard/sort-meeting-provider/sort-meeting-provider.component';
import { ConnectStripeStepperComponent } from '@dashboard/payment-dashboard/connect-stripe-stepper/connect-stripe-stepper.component';
import { CustomEmailPreviewComponent } from '@dashboard/settings/common/custom-email-preview/custom-email-preview.component';
import { AppointmentStatusAddComponent } from '@dashboard/settings/pages/appointment-status-settings/appointment-status-add/appointment-status-add.component';
import { ExportLogsComponent } from '@dashboard/settings/pages/audit-logs/export-logs/export-logs.component';
import { BexioSettingsComponent } from '@dashboard/settings/pages/bexio-settings/bexio-settings.component';
import { CreateBookingTypeAttributeComponent } from '@dashboard/settings/pages/booking-type-attributes/create-booking-type-attribute/create-booking-type-attribute.component';
import { CreateBookingTypeComponent } from '@dashboard/settings/pages/booking-types/create-booking-type/create-booking-type.component';
import { CreateCalendarTypeComponent } from '@dashboard/settings/pages/calendar-types-settings/create-calendar-type/create-calendar-type.component';
import { CalendlySyncComponent } from '@dashboard/settings/pages/calendly-sync/calendly-sync.component';
import { CustomerTypesFormComponent } from '@dashboard/settings/pages/customer-types-settings/customer-types-form/customer-types-form.component';
import { EmailProviderSettingsComponent } from '@dashboard/settings/pages/email-provider-settings/email-provider-settings.component';
import { EmailHistoryComponent } from '@dashboard/settings/pages/email-sms-view-settings/email-history/email-history.component';
import { EmailPreviewComponent } from '@dashboard/settings/pages/email-sms-view-settings/email-preview/email-preview.component';
import { EmailSmsPreviewComponent } from '@dashboard/settings/pages/email-sms-view-settings/email-sms-preview/email-sms-preview.component';
import { ExivoComponent } from '@dashboard/settings/pages/exivo/exivo.component';
import { CreateNotificationComponent } from '@dashboard/settings/pages/global-notifications/create-notification/create-notification.component';
import { InxmailComponent } from '@dashboard/settings/pages/inxmail/inxmail.component';
import { LexofficeSettingsComponent } from '@dashboard/settings/pages/lexoffice-settings/lexoffice-settings.component';
import { CreateApiKeyComponent } from '@dashboard/settings/pages/manage-api-keys/pages/create-api-key/create-api-key.component';
import { PaypalSettingsComponent } from '@dashboard/settings/pages/paypal-settings/paypal-settings.component';
import { ActivateMfaComponent } from '@dashboard/settings/pages/resource-detail-settings/tabs/resource-security/activate-mfa/activate-mfa.component';
import { ResetMfaComponent } from '@dashboard/settings/pages/resource-detail-settings/tabs/resource-security/reset-mfa/reset-mfa.component';
import { ShowBackupCodeComponent } from '@dashboard/settings/pages/resource-detail-settings/tabs/resource-security/show-backup-code/show-backup-code.component';
import { CreateRolesComponent } from '@dashboard/settings/pages/roles-permissions/pages/create-roles/create-roles.component';
import { ViewPermissionsComponent } from '@dashboard/settings/pages/roles-permissions/pages/view-permissions/view-permissions.component';
import { SaferpaySettingsComponent } from '@dashboard/settings/pages/saferpay-settings/saferpay-settings.component';
import { SalesforceComponent } from '@dashboard/settings/pages/salesforce/salesforce.component';
import { ShowPossibleWorkerComponent } from '@dashboard/settings/pages/services-settings/show-possible-worker/show-possible-worker.component';
import { SingleSignOnSettingsComponent } from '@dashboard/settings/pages/single-sign-on-settings/single-sign-on-settings.component';
import { SmsProviderSettingsComponent } from '@dashboard/settings/pages/sms-provider-settings/sms-provider-settings.component';
import { DeleteDialogComponent } from '@dashboard/settings/pages/stores-settings/delete-dialog/delete-dialog.component';
import { StripeSettingsComponent } from '@dashboard/settings/pages/stripe-settings/stripe-settings.component';
import { TaxesFormComponent } from '@dashboard/settings/pages/taxes-settings/taxes-form/taxes-form.component';
import { WebhookSettingsComponent } from '@dashboard/settings/pages/webhook-settings/webhook-settings.component';
import { CancelSubscriptionComponent } from '@dashboard/settings/pages/your-subscription-settings/cancel-subscription/cancel-subscription.component';
import { SmartActionTriggerDialogComponent } from '@dashboard/smart-actions/smart-action-card/smart-action-trigger-dialog/smart-action-trigger-dialog.component';
import { CreateEditConfigurationDialogComponent } from '@dashboard/website-integration-dashboard/create-edit-configuration-dialog/create-edit-configuration-dialog.component';
import { RebookGroupAppointmentDialogComponent } from '@events/rebook-group-appointment-dialog/rebook-group-appointment-dialog.component';
import { ImageModalComponent } from '@modals/image-modal/image-modal.component';
import { YoutubeModalComponent } from '@modals/youtube-modal/youtube-modal.component';
import { CalioSettingsFormComponent } from '@startsite/calio-settings-form/calio-settings-form.component';
import { AppointmentStatusHistoryComponent } from '@startsite/unconfirmed-appointments/appointment-status-history/appointment-status-history.component';
import { UpdateAppointmentStatusComponent } from '@startsite/unconfirmed-appointments/update-appointment-status/update-appointment-status.component';

@Injectable({
  providedIn: 'root',
})
export class ImportDynamicComponentService {

  importDealFormComp(): () => Promise<Type<DealsFormComponent>> {
    return () => import('@dashboard/deals-dashboard/deals-form/deals-form.component').then(
      component => component.DealsFormComponent
    );
  }

  importCalioSettingFormComp(): () => Promise<Type<CalioSettingsFormComponent>> {
    return () => import('@startsite/calio-settings-form/calio-settings-form.component').then(
      component => component.CalioSettingsFormComponent
    );
  }

  importCustomerNotesFormComp(): () => Promise<Type<CustomerNotesFormComponent>> {
    return () => import('@calio-dynamic-lib/customer-notes-form/customer-notes-form.component').then(
      component => component.CustomerNotesFormComponent
    );
  }

  importCreateSettingComponent(): () => Promise<Type<CreateFormComponent>> {
    return () => import('@dashboard/crm-dashboard/common/manage-partners-settings/create-form/create-form.component').then(
      component => component.CreateFormComponent
    );
  }

  importQuestionFieldFormComp(): () => Promise<Type<QuestionFieldsFormComponent>> {
    return () => import('@calio-dynamic-lib/question-fields/question-fields-form/question-fields-form.component').then(
      component => component.QuestionFieldsFormComponent
    );
  }

  importCompanySettingTranslationFormComp(): () => Promise<Type<CompanySettingTranslationFormComponent>> {
    return () => import('@calio-dynamic-lib/company-setting-translation/company-setting-translation-form/company-setting-translation-form.component').then(
      component => component.CompanySettingTranslationFormComponent
    );
  }

  importStripeCardDialogComp(): () => Promise<Type<StripeCardDialogComponent>> {
    return () => import('@calio-dynamic-lib/stripe-card-dialog/stripe-card-dialog.component').then(
      component => component.StripeCardDialogComponent
    );
  }

  importResellerInviteComp(): () => Promise<Type<ResellerInviteDialogComponent>> {
    return () => import('@dashboard/dashboard-common/reseller-feature/reseller-invite-dialog/reseller-invite-dialog.component').then(
      component => component.ResellerInviteDialogComponent
    );
  }

  importServiceCategoryComp(): () => Promise<Type<CreateServiceCategoryComponent>> {
    return () => import('@calio-dynamic-lib/appointment-forms/create-service-category/create-service-category.component').then(
      component => component.CreateServiceCategoryComponent
    );
  }

  importAppointmentServiceComp(): () => Promise<Type<AppointmentServicesFormComponent>> {
    return () => import('@calio-dynamic-lib/appointment-forms/appointment-services-form/appointment-services-form.component').then(
      component => component.AppointmentServicesFormComponent
    );
  }

  importAppointmentServiceQuestionComp(): () => Promise<Type<AppointmentServicesQuestionsComponent>> {
    return () => import('@calio-dynamic-lib/appointment-forms/appointment-services-questions/appointment-services-questions.component').then(
      component => component.AppointmentServicesQuestionsComponent
    );
  }

  importEmployeeFormComp(): () => Promise<Type<EmployeeFormDialogComponent>> {
    return () => import('@calio-dynamic-lib/employee-form/employee-form-dialog/employee-form-dialog.component').then(
      component => component.EmployeeFormDialogComponent
    );
  }

  importStoreFormComp(): () => Promise<Type<StoresFormComponent>> {
    return () => import('@calio-dynamic-lib/store-form/stores-form/stores-form.component').then(
      component => component.StoresFormComponent
    );
  }

  importSmartWidgetStepFormComp(): () => Promise<Type<SmartWidgetStepFormComponent>> {
    return () => import('@calio-dynamic-lib/smart-widget-step-form/smart-widget-step-form.component').then(
      component => component.SmartWidgetStepFormComponent
    );
  }

  importWidgetAppointmentGroupFormComp(): () => Promise<Type<WidgetAppointmentGroupFormComponent>> {
    return () => import('@calio-dynamic-lib/widget-appointment-group/widget-appointment-group-form/widget-appointment-group-form.component').then(
      component => component.WidgetAppointmentGroupFormComponent
    );
  }

  importCalendarSetupDialogComp(): () => Promise<Type<CalendarSetupComponent>> {
    return () => import('@calio-dynamic-lib/calendar-setup/calendar-setup.component').then(
      component => component.CalendarSetupComponent
    );
  }

  importAvailabilitiesFormSwitcherComp(): () => Promise<Type<AvailabilitiesFormSwitcherComponent>> {
    return () => import('@calio-dynamic-lib/availability-forms/availabilities-form-switcher/availabilities-form-switcher.component').then(
      component => component.AvailabilitiesFormSwitcherComponent
    );
  }

  importAbsensesFormComp(): () => Promise<Type<AbsensesFormComponent>> {
    return () => import('@calio-dynamic-lib/absences-forms/absenses-form/absenses-form.component').then(
      component => component.AbsensesFormComponent
    );
  }

  importTemplateFormDialogComp(): () => Promise<Type<TemplatesFormDialogComponent>> {
    return () => import('@calio-dynamic-lib/template-form-dialog/templates-form-dialog/templates-form-dialog.component').then(
      component => component.TemplatesFormDialogComponent
    );
  }

  importCustomerTypesFormComp(): () => Promise<Type<CustomerTypesFormComponent>> {
    return () => import('@dashboard/settings/pages/customer-types-settings/customer-types-form/customer-types-form.component').then(
      component => component.CustomerTypesFormComponent
    );
  }

  importCreateCalendarTypeComp(): () => Promise<Type<CreateCalendarTypeComponent>> {
    return () => import('@dashboard/settings/pages/calendar-types-settings/create-calendar-type/create-calendar-type.component').then(
      component => component.CreateCalendarTypeComponent
    );
  }

  importPartnerSettingModalComp(): () => Promise<Type<PartnerSettingModalComponent>> {
    return () => import('@calio-dynamic-lib/partner-setting-modal/partner-setting-modal.component').then(
      component => component.PartnerSettingModalComponent
    );
  }

  importJsonViewerComp(): () => Promise<Type<JsonViewerComponent>> {
    return () => import('@calio-dynamic-lib/json-viewer/json-viewer.component').then(
      component => component.JsonViewerComponent
    );
  }

  importJsonDiffViewerComp(): () => Promise<Type<JsonDiffViewerComponent>> {
    return () => import('@calio-dynamic-lib/json-diff-viewer/json-diff-viewer.component').then(
      component => component.JsonDiffViewerComponent
    );
  }

  importTaxesFormComp(): () => Promise<Type<TaxesFormComponent>> {
    return () => import('@dashboard/settings/pages/taxes-settings/taxes-form/taxes-form.component').then(
      component => component.TaxesFormComponent
    );
  }

  importConnectStripeStepperComp(): () => Promise<Type<ConnectStripeStepperComponent>> {
    return () => import('@dashboard/payment-dashboard/connect-stripe-stepper/connect-stripe-stepper.component').then(
      component => component.ConnectStripeStepperComponent
    );
  }

  importCalensoWidgetModalComp(): () => Promise<Type<CalensoWidgetModalComponent>> {
    return () => import('@calio-dynamic-lib/calenso-widget-modal/calenso-widget-modal.component').then(
      component => component.CalensoWidgetModalComponent
    );
  }

  importRebookAppointmentComp(): () => Promise<Type<RebookAppointmentDialogComponent>> {
    return () => import('@appointments/rebook-appointment-dialog/rebook-appointment-dialog.component').then(
      component => component.RebookAppointmentDialogComponent
    );
  }

  importConfirmationModalComp(): () => Promise<Type<ConfirmationModalComponent>> {
    return () => import('@calio-dynamic-lib/confirmation-modal/confirmation-modal.component').then(
      component => component.ConfirmationModalComponent
    );
  }

  importDeleteEventConfirmationModalComp(): () => Promise<Type<DeleteEventConfirmationModalComponent>> {
    return () => import('@calio-dynamic-lib/delete-event-confirmation-modal/delete-event-confirmation-modal.component').then(
      component => component.DeleteEventConfirmationModalComponent
    );
  }

  importEventSlotParticipantComp(): () => Promise<Type<EventSlotParticipantComponent>> {
    return () => import('@dashboard/events-dashboard/common/event-slot-participant/event-slot-participant.component').then(
      component => component.EventSlotParticipantComponent
    );
  }

  importEventSlotEditComp(): () => Promise<Type<EventSlotEditDialogComponent>> {
    return () => import('@dashboard/events-dashboard/common/event-slot-edit-dialog/event-slot-edit-dialog.component').then(
      component => component.EventSlotEditDialogComponent
    );
  }

  importSmartActionTriggerComp(): () => Promise<Type<SmartActionTriggerDialogComponent>> {
    return () => import('@dashboard/smart-actions/smart-action-card/smart-action-trigger-dialog/smart-action-trigger-dialog.component').then(
      component => component.SmartActionTriggerDialogComponent
    );
  }

  importImageUploadDialogComp(): () => Promise<Type<ImageUploadDialogComponent>> {
    return () => import('@calio-dynamic-lib/image-upload-dialog/image-upload-dialog.component').then(
      component => component.ImageUploadDialogComponent
    );
  }

  importDeleteAccountConfirmationDialogComp(): () => Promise<Type<DeleteAccountConfirmationModalComponent>> {
    return () => import('@calio-dynamic-lib/delete-account-confirmation-modal/delete-account-confirmation-modal.component').then(
      component => component.DeleteAccountConfirmationModalComponent
    );
  }

  importCustomerBookingDeleteComp(): () => Promise<Type<CustomerBookingDeleteConfirmationDialogComponent>> {
    return () => import('@dashboard/crm-dashboard/common/customer-booking-delete-confirmation-dialog/customer-booking-delete-confirmation-dialog.component').then(
      component => component.CustomerBookingDeleteConfirmationDialogComponent
    );
  }

  importEventCopyCodeComp(): () => Promise<Type<EventCopyCodeDialogComponent>> {
    return () => import('@dashboard/events-dashboard/common/event-copy-code-dialog/event-copy-code-dialog.component').then(
      component => component.EventCopyCodeDialogComponent
    );
  }

  importEventAddSlotBottomSheetComp(): () => Promise<Type<EventAddSlotBottomsheetComponent>> {
    return () => import('@dashboard/events-dashboard/common/event-add-slot-bottomsheet/event-add-slot-bottomsheet.component').then(
      component => component.EventAddSlotBottomsheetComponent
    );
  }

  importBryntumEventEditorComp(): () => Promise<Type<BryntumEventEditorComponent>> {
    return () => import('@calio-dynamic-lib/bryntum-event-forms/bryntum-event-editor/bryntum-event-editor.component').then(
      component => component.BryntumEventEditorComponent
    );
  }

  importCalioPermissionModalComp(): () => Promise<Type<CalioPermissionModalComponent>> {
    return () => import('@calio-dynamic-lib/calio-permission-modal/calio-permission-modal.component').then(
      component => component.CalioPermissionModalComponent
    );
  }

  viewPermissionModalComp(): () => Promise<Type<ViewPermissionsComponent>> {
    return () => import('@dashboard/settings/pages/roles-permissions/pages/view-permissions/view-permissions.component').then(
      component => component.ViewPermissionsComponent
    );
  }

  createRolesComp(): () => Promise<Type<CreateRolesComponent>> {
    return () => import('@dashboard/settings/pages/roles-permissions/pages/create-roles/create-roles.component').then(
      component => component.CreateRolesComponent
    );
  }

  createApiComp(): () => Promise<Type<CreateApiKeyComponent>> {
    return () => import('@dashboard/settings/pages/manage-api-keys/pages/create-api-key/create-api-key.component').then(
      component => component.CreateApiKeyComponent
    );
  }

  importMeetingSetupDialogComp(): () => Promise<Type<MeetingSetupComponent>> {
    return () => import('@calio-dynamic-lib/meeting-setup/meeting-setup.component').then(
      component => component.MeetingSetupComponent
    );
  }

  importReleaseNotesDialogComp(): () => Promise<Type<ReleaseNotesSettingsFormComponent>> {
    return () => import('@calio-dynamic-lib/release-notes/release-notes-settings-form/release-notes-settings-form.component').then(
      component => component.ReleaseNotesSettingsFormComponent
    );
  }

  importPartnerReleaseNotesDialogComp(): () => Promise<Type<PartnerReleaseNotesListComponent>> {
    return () => import('@calio-dynamic-lib/release-notes/partner-release-notes-list/partner-release-notes-list.component').then(
      component => component.PartnerReleaseNotesListComponent
    );
  }

  importSmartWidgetFormDialogComp(): () => Promise<Type<SmartWidgetFormComponent>> {
    return () => import('@calio-dynamic-lib/smart-widget-form/smart-widget-form.component').then(
      component => component.SmartWidgetFormComponent
    );
  }

  importQrCodeDialogComp(): () => Promise<Type<QrCodeDialogComponent>> {
    return () => import('@calio-dynamic-lib/qr-code-dialog/qr-code-dialog.component').then(
      component => component.QrCodeDialogComponent
    );
  }

  importSmartWidgetEmbedCodeDialogComp(): () => Promise<Type<SmartWidgetEmbedCodeDialogComponent>> {
    return () => import('@calio-dynamic-lib/smart-widget-embed-code-dialog/smart-widget-embed-code-dialog.component').then(
      component => component.SmartWidgetEmbedCodeDialogComponent
    );
  }

  importTemplateRestrictionDialogComp(): () => Promise<Type<TemplatesRestrictionFormComponent>> {
    return () => import('@calio-dynamic-lib/template-form-dialog/templates-restriction-form/templates-restriction-form.component').then(
      component => component.TemplatesRestrictionFormComponent
    );
  }

  importBookingQuestionOrderingComp(): () => Promise<Type<BookingQuestionOrderingComponent>> {
    return () => import('@calio-dynamic-lib/booking-question-ordering/booking-question-ordering.component').then(
      component => component.BookingQuestionOrderingComponent
    );
  }

  formFieldHistory(): () => Promise<Type<CustomFormFieldHistoryComponent>> {
    return () => import('@calio-dynamic-lib/custom-form-field-history/custom-form-field-history.component').then(
      component => component.CustomFormFieldHistoryComponent
    );
  }

  auditLogsExport(): () => Promise<Type<ExportLogsComponent>> {
    return () => import('@dashboard/settings/pages/audit-logs/export-logs/export-logs.component').then(
      component => component.ExportLogsComponent
    );
  }

  webhook(): () => Promise<Type<WebhookComponent>> {
    return () => import('@calio-dynamic-lib/webhook/webhook.component').then(
      component => component.WebhookComponent
    );
  }

  appointmentStatus(): () => Promise<Type<AppointmentStatusAddComponent>> {
    return () => import('@dashboard/settings/pages/appointment-status-settings/appointment-status-add/appointment-status-add.component').then(
      component => component.AppointmentStatusAddComponent
    );
  }

  updateAppointmentStatus(): () => Promise<Type<UpdateAppointmentStatusComponent>> {
    return () => import('@startsite/unconfirmed-appointments/update-appointment-status/update-appointment-status.component').then(
      component => component.UpdateAppointmentStatusComponent
    );
  }

  showAppointmentStatusHistory(): () => Promise<Type<AppointmentStatusHistoryComponent>> {
    return () => import('@startsite/unconfirmed-appointments/appointment-status-history/appointment-status-history.component').then(
      component => component.AppointmentStatusHistoryComponent
    );
  }

  youtubeVideoPreview(): () => Promise<Type<YoutubeModalComponent>> {
    return () => import('@modals/youtube-modal/youtube-modal.component').then(
      component => component.YoutubeModalComponent
    );
  }

  sortMeetingProvider(): () => Promise<Type<SortMeetingProviderComponent>> {
    return () => import('@dashboard/meeting-dashboard/sort-meeting-provider/sort-meeting-provider.component').then(
      component => component.SortMeetingProviderComponent
    );
  }

  manageSubscription(): () => Promise<Type<ManageSubscriptionComponent>> {
    return () => import('@dashboard/crm-dashboard/common/customer-admin-section/manage-subscription/manage-subscription.component').then(
      component => component.ManageSubscriptionComponent
    );
  }

  cancelSubscription(): () => Promise<Type<CancelSubscriptionComponent>> {
    return () => import('@dashboard/settings/pages/your-subscription-settings/cancel-subscription/cancel-subscription.component').then(
      component => component.CancelSubscriptionComponent
    );
  }

  manageBusinessHoursDialog(): () => Promise<Type<ManageBookingHoursComponent>> {
    return () => import('@calio-dynamic-lib/manage-booking-hours/manage-booking-hours.component').then(
      component => component.ManageBookingHoursComponent
    );
  }

  emailSmsPreview(): () => Promise<Type<EmailSmsPreviewComponent>> {
    return () => import('@dashboard/settings/pages/email-sms-view-settings/email-sms-preview/email-sms-preview.component').then(
      component => component.EmailSmsPreviewComponent
    );
  }

  openHtmlPreview(): () => Promise<Type<EmailPreviewComponent>> {
    return () => import('@dashboard/settings/pages/email-sms-view-settings/email-preview/email-preview.component').then(
      component => component.EmailPreviewComponent
    );
  }

  emailHistory(): () => Promise<Type<EmailHistoryComponent>> {
    return () => import('@dashboard/settings/pages/email-sms-view-settings/email-history/email-history.component').then(
      component => component.EmailHistoryComponent
    );
  }

  resetWorkerMfa(): () => Promise<Type<ResetMfaComponent>> {
    return () => import('@dashboard/settings/pages/resource-detail-settings/tabs/resource-security/reset-mfa/reset-mfa.component').then(
      component => component.ResetMfaComponent
    );
  }

  activateMfa(): () => Promise<Type<ActivateMfaComponent>> {
    return () => import('@dashboard/settings/pages/resource-detail-settings/tabs/resource-security/activate-mfa/activate-mfa.component').then(
      component => component.ActivateMfaComponent
    );
  }

  showMfaBackupCodes(): () => Promise<Type<ShowBackupCodeComponent>> {
    return () => import('@dashboard/settings/pages/resource-detail-settings/tabs/resource-security/show-backup-code/show-backup-code.component').then(
      component => component.ShowBackupCodeComponent
    );
  }

  showPossibleWorkers(): () => Promise<Type<ShowPossibleWorkerComponent>> {
    return () => import('@dashboard/settings/pages/services-settings/show-possible-worker/show-possible-worker.component').then(
      component => component.ShowPossibleWorkerComponent
    );
  }

  showGlobalNotification(): () => Promise<Type<CreateNotificationComponent>> {
    return () => import('@dashboard/settings/pages/global-notifications/create-notification/create-notification.component').then(
      component => component.CreateNotificationComponent
    );
  }

  openCustomerModalForm(): () => Promise<Type<CalioCreateCustomerModalFormComponent>> {
    return () => import('@calio-dynamic-lib/calio-create-customer-modal-form/calio-create-customer-modal-form.component').then(
      component => component.CalioCreateCustomerModalFormComponent
    );
  }

  ImageModalComponent(): () => Promise<Type<ImageModalComponent>> {
    return () => import('@modals/image-modal/image-modal.component').then(
      component => component.ImageModalComponent
    );
  }

  calioCustomerBookingQuestions(): () => Promise<Type<LandingpageBookingQuestionsFormComponent>> {
    return () => import('@calio-dynamic-lib/landingpage-booking-questions-form/landingpage-booking-questions-form.component').then(
      component => component.LandingpageBookingQuestionsFormComponent
    );
  }

  mappedServicesDialog(): () => Promise<Type<CustomFieldMappedServicesComponent>> {
    return () => import('@calio-dynamic-lib/custom-field-mapped-services/custom-field-mapped-services.component').then(
      component => component.CustomFieldMappedServicesComponent
    );
  }

  rebookGroupAppointmentDialog(): () => Promise<Type<RebookGroupAppointmentDialogComponent>> {
    return () => import('@events/rebook-group-appointment-dialog/rebook-group-appointment-dialog.component').then(
      component => component.RebookGroupAppointmentDialogComponent
    );
  }

  deleteStoreConfirmationDialog(): () => Promise<Type<DeleteDialogComponent>> {
    return () => import('@dashboard/settings/pages/stores-settings/delete-dialog/delete-dialog.component').then(
      component => component.DeleteDialogComponent
    );
  }

  thirdPartyIntegratinoApiErrorDialog(): () => Promise<Type<ApiErrorDetailsDialogComponent>> {
    return () => import('@calio-dynamic-lib/third-party-api-error-details-dialog/third-party-api-error-details-dialog.component').then(
      component => component.ApiErrorDetailsDialogComponent
    );
  }

  generateQrCodeComponent(): () => Promise<Type<GenerateQrCodeComponent>> {
    return () => import('@dashboard/conference-dashboard/generate-qr-code/generate-qr-code.component').then(
      component => component.GenerateQrCodeComponent
    );
  }

  conferenceWorkersListDialog(): () => Promise<Type<WorkersListDialogComponent>> {
    return () => import('@dashboard/conference-dashboard/workers-list-dialog/workers-list-dialog.component').then(
      component => component.WorkersListDialogComponent
    );
  }

  supportUserLoginDialog(): () => Promise<Type<SupportLoginDialogComponent>> {
    return () => import('@dashboard/crm-dashboard/common/customer-admin-section/support-login-dialog/support-login-dialog.component').then(
      component => component.SupportLoginDialogComponent
    );
  }

  createIntegrationConfigDialog(): () => Promise<Type<CreateEditConfigurationDialogComponent>> {
    return () => import('@dashboard/website-integration-dashboard/create-edit-configuration-dialog/create-edit-configuration-dialog.component').then(
      component => component.CreateEditConfigurationDialogComponent
    );
  }

  customerLinksTableDialog(): () => Promise<Type<CustomerLinksComponent>> {
    return () => import('@dashboard/conference-dashboard/customer-links/customer-links.component').then(
      component => component.CustomerLinksComponent
    );
  }

  inxmailComponent(): () => Promise<Type<InxmailComponent>> {
    return () => import('@dashboard/settings/pages/inxmail/inxmail.component').then(
      component => component.InxmailComponent
    );
  }

  emailProviderSettingsComponent(): () => Promise<Type<EmailProviderSettingsComponent>> {
    return () => import('@dashboard/settings/pages/email-provider-settings/email-provider-settings.component').then(
      component => component.EmailProviderSettingsComponent
    );
  }

  smsProviderSettingsComponent(): () => Promise<Type<SmsProviderSettingsComponent>> {
    return () => import('@dashboard/settings/pages/sms-provider-settings/sms-provider-settings.component').then(
      component => component.SmsProviderSettingsComponent
    );
  }

  salesforceComponent(): () => Promise<Type<SalesforceComponent>> {
    return () => import('@dashboard/settings/pages/salesforce/salesforce.component').then(
      component => component.SalesforceComponent
    );
  }

  webhookSettingsComponent(): () => Promise<Type<WebhookSettingsComponent>> {
    return () => import('@dashboard/settings/pages/webhook-settings/webhook-settings.component').then(
      component => component.WebhookSettingsComponent
    );
  }

  stripeSettingsComponent(): () => Promise<Type<StripeSettingsComponent>> {
    return () => import('@dashboard/settings/pages/stripe-settings/stripe-settings.component').then(
      component => component.StripeSettingsComponent
    );
  }

  bexioSettingsComponent(): () => Promise<Type<BexioSettingsComponent>> {
    return () => import('@dashboard/settings/pages/bexio-settings/bexio-settings.component').then(
      component => component.BexioSettingsComponent
    );
  }

  lexOfficeSettingsComponent(): () => Promise<Type<LexofficeSettingsComponent>> {
    return () => import('@dashboard/settings/pages/lexoffice-settings/lexoffice-settings.component').then(
      component => component.LexofficeSettingsComponent
    );
  }

  paypalSettingsComponent(): () => Promise<Type<PaypalSettingsComponent>> {
    return () => import('@dashboard/settings/pages/paypal-settings/paypal-settings.component').then(
      component => component.PaypalSettingsComponent
    );
  }

  saferPaySettingsComponent(): () => Promise<Type<SaferpaySettingsComponent>> {
    return () => import('@dashboard/settings/pages/saferpay-settings/saferpay-settings.component').then(
      component => component.SaferpaySettingsComponent
    );
  }

  exivoSettingsComponent(): () => Promise<Type<ExivoComponent>> {
    return () => import('@dashboard/settings/pages/exivo/exivo.component').then(
      component => component.ExivoComponent
    );
  }

  singleSignOnSettingsComponent(): () => Promise<Type<SingleSignOnSettingsComponent>> {
    return () => import('@dashboard/settings/pages/single-sign-on-settings/single-sign-on-settings.component').then(
      component => component.SingleSignOnSettingsComponent
    );
  }

  calendlySettingsComponent(): () => Promise<Type<CalendlySyncComponent>> {
    return () => import('@dashboard/settings/pages/calendly-sync/calendly-sync.component').then(
      component => component.CalendlySyncComponent
    );
  }

  predefineWorkflowListDialog(): () => Promise<any> {
    return () => import('@dashboard/workflow/predefine-workflow-list/predefine-workflow-list.component').then(
      component => component.PredefineWorkflowListComponent
    )
  }

  workflowListDetailsDialog(): () => Promise<any> {
    return () =>
      import('@dashboard/workflow/workflow-details/workflow-details.component').then((m) => m.WorkflowDetailsComponent);
  }
  createCustomEmailTemplateDialog(): () => Promise<any> {
    return () =>
      import('@dashboard/settings/common/custom-email-templates/custom-email-templates.component').then((m) => m.CustomEmailTemplatesComponent);
  }

  mergeCustomerDialog(): () => Promise<Type<MergeCustomersComponent>> {
    return () => import('@dashboard/crm-dashboard/common/merge-customers/merge-customers.component').then(
      component => component.MergeCustomersComponent
    );
  }

  openBookingTypesFormDialog(): () => Promise<Type<CreateBookingTypeComponent>> {
    return () => import('@dashboard/settings/pages/booking-types/create-booking-type/create-booking-type.component').then(
      component => component.CreateBookingTypeComponent
    );
  }

  openBookingTypeAttributeFormDialog(): () => Promise<Type<CreateBookingTypeAttributeComponent>> {
    return () => import('@dashboard/settings/pages/booking-type-attributes/create-booking-type-attribute/create-booking-type-attribute.component').then(
      component => component.CreateBookingTypeAttributeComponent
    );
  }

  customEmailTemplatePreviewDialog(): () => Promise<Type<CustomEmailPreviewComponent>> {
    return () => import('@dashboard/settings/common/custom-email-preview/custom-email-preview.component').then(
      component => component.CustomEmailPreviewComponent
    );
  }
}
